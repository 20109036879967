import React from 'react';
import { Container, Row, Col, Card, FormGroup, Label, Input } from 'reactstrap';
import basketballPreview from '../../assets/images/basketball.PNG';
import './style.css'
const BasketballSettings = ({ questionset, timer, onTimerChange, onCreateRoom }) => {
  return (
    <Container className="h-100">
      <Row className="h-100">
        <Col xs="12">
          <Card className="text-center" >
            <Container className="h-100">
              <Row>
                <Col lg="12">
                  <h1 className='titan-one-regular'>Trivia Dunk</h1>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <img className=" settings-game-image" src={basketballPreview} alt="basketball" />
                </Col>
              </Row>
              <Row className="h-100">
                <Col md="6">
                  <Container className="text-center nunito">
                    <Row className="text-center">
                      <Col xs="12">
                        <h3 className="text-center mb-3">{questionset.title}</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        {questionset.image && (
                          <img className="setup-image mb-3" src={questionset.image} alt="Question Set" />
                        )}
                      </Col>
                      <Col md="6">
                        {questionset.description && (
                          <p className="mb-3">{questionset.description}</p>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col xs="6" className="mb-3 vertical-center justify-content-center">
                  <Container>
                    <Row>
                    <Col xs="12">
                      <FormGroup row>
                        <Label for="timer" sm={4} className="text-sm-right align-self-center font-weight-bold "> {/* Adjust sm value to control label width */}
                          Time (minutes)
                        </Label>
                        <Col sm={8}> {/* Adjust sm value to control input width */}
                          <Input
                            type="number"
                            id="timer"
                            min="3"
                            max="30"
                            value={timer}
                            onChange={onTimerChange}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                      <Col xs="12">
                        <button
                          onClick={onCreateRoom}
                          className="btn btn-rounded nav-btn-light-blue navbar-btn"
                        >
                          Start
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BasketballSettings;
