import React from 'react';
import './style.css';
import io from 'socket.io-client';
import { useEffect, useRef, useState} from "react";
import { Input, Container, Row, Col, Card, Label, CardText, 
    Modal,  ModalBody, Button} from 'reactstrap';
import { useParams } from 'react-router-dom';

const BuzzerButton = () => {
    const {id} = useParams();
    const [roomIdInput, setRoomIdInput] = useState('');
    const [joinedRoomId, setJoinedRoomId] = useState(null);
    const [teamOptions, setTeamOptions] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [socket, setSocket] = useState(null);
    useEffect(() => {
        const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}/jeopardy`, {
            withCredentials: true
        });
        setSocket(newSocket);
        return () => {
            newSocket.disconnect();
        };
    }, []);
    useEffect(()=>{
        if(id && socket){
            socket.on('teamOptions', handleTeamOptions);
            socket.emit('joinRoom', id);
            setRoomIdInput(id)
            setJoinedRoomId(id); // Store joined
            }

    },[socket])

    const onRoomIdChange = e=>{
        setRoomIdInput(e.target.value);
    }
    const handleTeamOptions = (options) => {
        setTeamOptions(options);
        // Turn off the listener after receiving data
        socket.off('teamOptions', handleTeamOptions);
    };

    const handleJoinRoom = () => {
        socket.on('teamOptions', handleTeamOptions);
        socket.emit('joinRoom', roomIdInput);
        setJoinedRoomId(roomIdInput); // Store joined room ID for UI feedback
    };

    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
        socket.emit('selectTeam', team, roomIdInput); // Send selected team to server
    };
    const handleBuzz = () => {
        socket.emit('buzz', roomIdInput); // Notify server of buzzer press
    };

  return (
    <div className={"buzzer-background vertical-center " + (selectedTeam? '':'waiting-room')}>
        {selectedTeam ?
        
        <div className="buzzer-container">
            <button className="buzzer-button btn titan-one-regular" onClick={()=>handleBuzz()}><span className='buzzer-button-front'>Kooblo!</span></button>
        </div>
        :   joinedRoomId? (
            <div>
                    <Container>
                        <Card className="text-center justify-content-center titan-one-small buzzer-team-select">

                            <Row className="text-center">
                                <Col>
                                <div>
                                    <h3>Select Team</h3>
                                </div>
                                </Col>
                            </Row>
                            <Row className='text-center justify-content-center'>
                                {teamOptions && Object.keys(teamOptions).map(key => (
                                    <Col xs="2" key={key} onClick={e=>handleTeamSelect(key)}>
                                        <img className='team-select-image' src={teamOptions[key].image}></img>
                                        <p>{teamOptions[key].name}</p>
                                    </Col>
                                ))}
                            </Row>
                        </Card>

                    </Container>



            </div>
  
            )
            :
            <Container className="">
                <Row className="h-100">
                    <Col xs='3'/>
                    <Col xs='6'>
                        <Card className="text-center justify-content-center titan-one-small ">
                            <h3>Enter Room ID</h3>
                            <Input type="text" id="gameId" className='text-center game-id-input'onChange={e=>{onRoomIdChange(e)}}/>
                            <div>
                                <button onClick={e=>handleJoinRoom()} className="btn btn-rounded nav-btn-light-blue navbar-btn">Enter</button>

                            </div>

                        </Card>

                    </Col>

                </Row>
            </Container>

            


        }
    </div>

  );
};

export default BuzzerButton;
