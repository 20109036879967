// src/components/Spawner.js
import Phaser from 'phaser';
import Trap from './obstacles/Trap'; // Import your Trap class
import RotatingSpikes from './obstacles/RotatingSpikes'; // Import your RotatingSpikes class
import Pipe from './obstacles/Pipe'; // Import your Pipe class
import Bomb from './obstacles/Bomb'; // Import your Bomb class
import Bee from './obstacles/Bee'; // Import your Bee class
import Coin from './Coin'; // Import your Coin class
import { irandom, irandom_range } from '../util'; // Import your utility functions

class Spawner {
  constructor(scene) {
    this.scene = scene;
    this.active = true;
    this.spawn();
    
  }

  spawn() {
    if (!this.active) return;
    
    const type = irandom(4);
    switch (type) {
      case 0:
        this.spawnTrap();
        break;
      case 1:
        this.spawnBee();

        break;
      case 2:
        this.spawnPipe();
        break;
      case 3:
        this.spawnBomb();
        break;
      case 4:
        this.spawnDifficultCombo(); // New difficult combination case
        break;
        default:
        break;
    }
  }
  spawnDifficultCombo() {
    // Number of Bombs and Bees to spawn
    const maxBombs = irandom_range(1, 3);;
    const maxBees =irandom_range(1, 3);;
  
    // Spawn multiple Bees
    for (let i = 0; i < maxBees; i++) {
      const bee = new Bee(this.scene, 1400 + i * 200, irandom_range(100, 400)); // Adjust horizontal offset
      this.scene.groups.obstacles.add(bee);
    }
  
    // Spawn multiple Bombs
    for (let i = 0; i < maxBombs; i++) {
      const bomb = new Bomb(this.scene, 1600 + i * 200, irandom_range(100, 400)); // Adjust horizontal offset
      this.scene.groups.obstacles.add(bomb);
    }
  
    // Spawn a Pipe with some horizontal offset
    const pipe = new Pipe(this.scene, 2400); // Adjust position if needed
    this.scene.groups.obstacles.add(pipe);
  
    // Optionally, spawn some coins after the difficult combo
    this.spawnCoins(2200); // Adjust position to ensure it appears after the combo
  
    // Schedule the next spawn after a delay
    this.scene.time.delayedCall(5000, () => this.spawn(), [], this);
  }
  
  spawnTrap() {
    const items = irandom_range(1, 2);
    for (let i = 0; i < items; i++) {
      const trap = new Trap(this.scene, 1400 + i * 800, irandom_range(50, 250));
      this.spawnCoins(trap.x + 300);
    }

    this.scene.time.delayedCall(3000 * items, () => this.spawn(), [], this);
  }

  spawnRotatingSpikes() {
    const items = irandom_range(1, 1);
    for (let i = 0; i < items; i++) {
      const spikes = new RotatingSpikes(this.scene, 1400 + i * 800, irandom_range(150, 350));
      this.scene.groups.obstacles.add(spikes); // Add to obstacles group
      this.spawnCoins(spikes.x + 300);
    }

    this.scene.time.delayedCall(2500 * items, () => this.spawn(), [], this);
  }

  spawnPipe() {
    const items = irandom_range(1, 1);
    for (let i = 0; i < items; i++) {
      const pipe = new Pipe(this.scene, 1400 + i * 600);

      this.spawnCoins(pipe.x + 264);
    }

    this.scene.time.delayedCall(3000 * items, () => this.spawn(), [], this);
  }

  spawnBomb() {
    const items = irandom_range(1, 2);
    for (let i = 0; i < items; i++) {
      const bomb = new Bomb(this.scene, 1400 + i * 400, irandom_range(100, 400));
      this.scene.groups.obstacles.add(bomb); // Add to obstacles group
    }
    this.spawnCoins(1600);
    this.scene.time.delayedCall(1600 * items, () => this.spawn(), [], this);
  }

  spawnBee() {
    const items = irandom_range(1, 2);
    for (let i = 0; i < items; i++) {
      const bee = new Bee(this.scene, 1400 + i * 400, irandom_range(100, 400));
      this.scene.groups.obstacles.add(bee); // Add to obstacles group
    }

    this.spawnCoins(1400);
    this.scene.time.delayedCall(1300 * items, () => this.spawn(), [], this);
  }

  spawnCoins(x) {
    const type = irandom(11);
    const yy = irandom_range(100, this.scene.sys.canvas.height - 279);
    const coinsGroup = this.scene.groups.coins; // Get the coins group
    
    switch (type) {
      case 0:
        coinsGroup.add(new Coin(this.scene, x, yy));
        break;
      case 1:
        coinsGroup.add(new Coin(this.scene, x - 29, yy));
        coinsGroup.add(new Coin(this.scene, x + 29, yy));
        break;
      case 2:
        coinsGroup.add(new Coin(this.scene, x - 54, yy));
        coinsGroup.add(new Coin(this.scene, x, yy));
        coinsGroup.add(new Coin(this.scene, x + 54, yy));
        break;
      case 3:
        coinsGroup.add(new Coin(this.scene, x, yy - 29));
        coinsGroup.add(new Coin(this.scene, x, yy + 29));
        break;
      case 4:
        coinsGroup.add(new Coin(this.scene, x, yy - 54));
        coinsGroup.add(new Coin(this.scene, x, yy));
        coinsGroup.add(new Coin(this.scene, x, yy + 54));
        break;
      case 5:
        coinsGroup.add(new Coin(this.scene, x - 29, yy + 29));
        coinsGroup.add(new Coin(this.scene, x + 29, yy - 29));
        break;
      case 6:
        coinsGroup.add(new Coin(this.scene, x + 29, yy - 29));
        coinsGroup.add(new Coin(this.scene, x - 29, yy + 29));
        break;
      case 7:
        coinsGroup.add(new Coin(this.scene, x - 54, yy + 54));
        coinsGroup.add(new Coin(this.scene, x, yy));
        coinsGroup.add(new Coin(this.scene, x + 54, yy - 54));
        break;
      case 8:
        coinsGroup.add(new Coin(this.scene, x + 54, yy - 54));
        coinsGroup.add(new Coin(this.scene, x, yy));
        coinsGroup.add(new Coin(this.scene, x - 54, yy + 54));
        break;
      case 9:
        coinsGroup.add(new Coin(this.scene, x, yy - 54));
        coinsGroup.add(new Coin(this.scene, x - 54, yy));
        coinsGroup.add(new Coin(this.scene, x, yy));
        coinsGroup.add(new Coin(this.scene, x + 54, yy));
        coinsGroup.add(new Coin(this.scene, x, yy + 54));
        break;
      case 10:
        coinsGroup.add(new Coin(this.scene, x - 54, yy - 54));
        coinsGroup.add(new Coin(this.scene, x + 54, yy - 54));
        coinsGroup.add(new Coin(this.scene, x, yy));
        coinsGroup.add(new Coin(this.scene, x - 54, yy + 54));
        coinsGroup.add(new Coin(this.scene, x + 54, yy + 54));
        break;
      default:
        break;
    }
  }
}

export default Spawner;
