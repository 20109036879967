import React, { createContext, useState, useEffect } from 'react';
import API from '../../utils/API'; // Import your API service

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(localStorage.getItem('authenticated') === 'true');
    const [user, setUser] = useState(null);
    
    useEffect(() => {   
        console.log('checking provider')

        // Check local storage to see if user is already authenticated
        const storedAuthenticated = localStorage.getItem('authenticated') === 'true';
        let storedUser = null;

        try {
          const userFromStorage = localStorage.getItem('user');
          storedUser = userFromStorage ? JSON.parse(userFromStorage) : null;
        } catch (error) {
          console.error('Error parsing user data from local storage:', error);
          storedUser = null; // Ensure storedUser is null if parsing fails
        }
    
        if (storedAuthenticated && storedUser) {
            setAuthenticated(true);
            setUser(storedUser);
        } 
        API.authenticate().then(res => {
            if (res.data.authenticated) {
                setAuthenticated(true);
                setUser(res.data.user);
                // Save to local storage
                localStorage.setItem('authenticated', 'true');
                localStorage.setItem('user', JSON.stringify(res.data.user));
            } else{
                localStorage.removeItem('authenticated');
                localStorage.removeItem('user');
                setAuthenticated(false);
                setUser(null);
            }
        }).catch(err =>{
            console.log(err)
        });
        }, []);

    const login = (userData) => {
        setAuthenticated(true);
        setUser(userData);
        console.log(userData);
        localStorage.setItem('authenticated', 'true');
        localStorage.setItem('user', JSON.stringify(userData));
        console.log('data set, now checking');
        console.log(localStorage.getItem('user'))
    };

    const logout = () => {
        setAuthenticated(false);
        setUser(null);
        localStorage.removeItem('authenticated');
        localStorage.removeItem('user');
    };

    return (
        <AuthContext.Provider value={{ authenticated, user, login, logout, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};