import React from 'react';
import "./style.css";
import { NavLink, Card, CardImg, CardText, CardBody,
  CardTitle, Button } from 'reactstrap';
import {useNavigate} from 'react-router-dom'
import koobloIMG from './Kooblo.png'

const ClassCard = (props) => {
  return (
    <div>

            <Card className="class-card-container text-center nunito">
                <CardTitle className="class-card-title m-0">{props.name}</CardTitle>
                <p className='m-0'>Students: {props.studentCount}</p>              
                <div className="class-link-group nunito">
                    <p className="class-card-custom-link" onClick={props.onViewClick}>View</p>
                    <p className="class-card-custom-link" onClick={props.onEditClick}>Edit</p>
                    <p className="class-card-custom-link class-card-custom-link-delete" onClick={props.onDeleteClick}>Delete</p>
                </div>

            </Card>
    </div>
  );
};

// Define default props to handle cases where props might be undefined
ClassCard.defaultProps = {
  photos: koobloIMG,
  name: 'Default Name',
  about: 'Default About Text'
};

export default ClassCard;