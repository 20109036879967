import React, { useState, useRef } from 'react';
import "./style.css";
import { Card, Container, Row, Col } from 'reactstrap';
import QRcodeImg from '../QRcodeImg';

const WaitingRoom = (props) => {
    const {
        teams = {},   // Default empty object if `teams` is undefined
        roomId,
        qrCodeUrl,
        errors = '', // Default empty string if `errors` is undefined
        onGameStart
    } = props;
    
    const [copied, setCopied] = useState(false);
    const [copiedPosition, setCopiedPosition] = useState({ top: 0, left: 0 });
    const websiteUrl = process.env.REACT_APP_BASE_URL;
    const linkRef = useRef(null); // Create a ref for the link container

    const handleCopyLink = (event) => {
        const link = `${websiteUrl}/buzzer/${roomId}`;
        navigator.clipboard.writeText(link).then(() => {
            if (linkRef.current) {
                const rect = linkRef.current.getBoundingClientRect();
                const { clientX, clientY } = event;
                
                // Calculate the position of the bubble relative to the container
                const top = clientY - rect.top;
                const left = clientX - rect.left;

                setCopiedPosition({ top: top + window.scrollY, left: left + window.scrollX });
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000); // Hide the "Copied" message after 2 seconds
            }
        }).catch(err => {
            console.error('Failed to copy the link: ', err);
        });
    };
    
    // Filter teams to include only those with 'joined' set to true
    const filteredTeams = Object.entries(teams).filter(([key, team]) => team.joined);

    return (
        <div className="background-blue-95vh vertical-center waiting-room">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs="3">
                    </Col>
                    <Col xs="6">
                        <Card className="text-center titan-one-regular">
                            {/* team select counter */}
                            <Container>
                                <Row className="justify-content-center align-items-center">
                                    <Col xs="6">              
                                        <h3 className="mb-3">Join Room {roomId}</h3>
                                        <div className="copy-link-wrapper" ref={linkRef}>
                                            <p
                                                className="copy-link"
                                                onClick={handleCopyLink}
                                                style={{ cursor: 'pointer', color: 'rgb(149, 0, 254)', textDecoration: 'underline' }}
                                            >
                                                Click to copy a link
                                            </p>
                                            <span
                                                className={`copied-bubble ${!copied ? 'hidden' : ''}`}
                                                style={{ top: copiedPosition.top, left: copiedPosition.left }}
                                            >
                                                Copied!
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs="6" className="mb-3 p-4">
                                        <QRcodeImg room={qrCodeUrl} />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs="12">
                                        <h4>Teams Joined</h4>
                                    </Col>
                                </Row>
                                <br />
                                {errors && <p className='text-danger'>{errors}</p>}
                                <Row className='user-list justify-content-center'>
                                    {filteredTeams.length > 0 ? (
                                        filteredTeams.map(([key, team]) => (
                                            <Col xs="auto" className="d-flex flex-column align-items-center m-2" key={key}>
                                                <div className="text-center">
                                                    <img src={team.image} alt={`${team.name}'s avatar`} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                                    <p>{team.name}</p>
                                                </div>
                                            </Col>
                                        ))
                                    ) : (
                                        <Col xs="12">
                                            <p>No teams joined yet.</p>
                                        </Col>
                                    )}
                                </Row>
                            </Container>
                            <button onClick={onGameStart} className="btn btn-rounded nav-btn-light-blue navbar-btn">Start</button>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WaitingRoom;
