import Phaser from 'phaser';

class AudioSwitch extends Phaser.GameObjects.Sprite {
  constructor(scene, options = {}) {
    const {
      type = 'sound',
      group = null,
      x = 0,
      y = 0,
      atlas = null,
      spriteOn = '',
      spriteOff = '',
      sndClick = null
    } = options;

    const initialTexture = atlas ? { key: atlas, frame: spriteOn } : spriteOn;

    super(scene, x, y, initialTexture.key, initialTexture.frame);

    this.type = type;
    this.group = group;
    this.atlas = atlas;
    this.spriteOn = spriteOn;
    this.spriteOff = spriteOff;
    this.sndClick = sndClick;

    this.volume = (type === 'sound')
      ? scene.game.audio.getVolumeSounds()
      : scene.game.audio.getVolumeMusic();

    this.setTexture(atlas, this.volume === 0 ? spriteOff : spriteOn);
    this.setOrigin(0.5);

    if (group) {
      group.add(this);
    } else {
      scene.add.existing(this);
    }

    this.setInteractive();
    this.on('pointerdown', this.switch, this);
  }

  switch() {
    const switchMethod = this.type === 'sound'
      ? this.scene.game.audio.switchVolumeSounds()
      : this.scene.game.audio.switchVolumeMusic();

    const volume = this.type === 'sound'
      ? this.scene.game.audio.getVolumeSounds()
      : this.scene.game.audio.getVolumeMusic();

    this.setTexture(this.atlas, volume === 0 ? this.spriteOff : this.spriteOn);

    if (this.sndClick) {
      this.scene.game.audio.playSound('sndClick');
    }
  }
}

export default AudioSwitch;
