import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import {AuthContext} from '../AuthContext/AuthContext'; // Your context for authentication

const ProtectedRoute = ({ children }) => {
  const { authenticated } = useContext(AuthContext); // Use your own auth context or state management
  if (!authenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
