class Orientation {
  constructor(orientation) {
    this.orientation = orientation;
    this.graphics = null;
    this.imagePhone = null;
    this.title = null;
    this.subtitle = null;
    this.scene = null;
  }

  // Call this function to initialize the Orientation check
  init(scene) {
    this.scene = scene;
    window.addEventListener('resize', this.checkOrientation.bind(this));
    this.checkOrientation(); // Check on initialization

  }

  checkOrientation() {
    // Exit if on desktop or if scene is not set    
    
    // if (this.scene.systems.game.device.os.desktop) return;
    const isLandscape = window.innerWidth > window.innerHeight;
    const shouldShow = (isLandscape && this.orientation === 'portrait') ||
                       (!isLandscape && this.orientation === 'landscape');
    if (shouldShow) {
      if (!this.graphics) {
        this.createGraphics();
      }
    } else {
      this.removeGraphics();
    }
  }

  createGraphics() {

    this.graphics = this.scene.add.graphics();
    this.graphics.fillStyle(0x1b2639, 1);

    this.graphics.fillRect(0, 0, this.scene.cameras.default.width, this.scene.cameras.default.height);

    this.imagePhone = this.scene.add.image(this.scene.cameras.default.centerX, this.scene.cameras.default.centerY - 150, 'preload', 'phone');
    this.imagePhone.setOrigin(0.5);
    if (this.orientation === 'landscape') this.imagePhone.setAngle(90);

    this.title = this.scene.add.text(this.scene.cameras.default.centerX, this.scene.cameras.default.centerY + 100, 'Please rotate your device', {
      font: '40px Verdana',
      fill: '#a4a8b0',
      fontWeight: 'bold',
      align: 'center'
    }).setOrigin(0.5);

    this.subtitle = this.scene.add.text(this.scene.cameras.default.centerX, this.scene.cameras.default.centerY + 180, `This game is designed for \n${this.orientation} mode`, {
      font: '30px Verdana',
      fill: '#a4a8b0',
      align: 'center'
    }).setOrigin(0.5);
    this.scene.scene.pause();
  }

  removeGraphics() {
    // Check and destroy each element, setting them to null after
    if (this.graphics) {
      this.graphics.clear();   // Clear the graphics content
      this.graphics.destroy(); // Destroy the graphics object
      this.graphics = null;
    }

    if (this.imagePhone) {
      this.imagePhone.destroy(); // Destroy the phone image
      this.imagePhone = null;
    }

    if (this.title) {
      this.title.destroy(); // Destroy the title text
      this.title = null;
    }

    if (this.subtitle) {
      this.subtitle.destroy(); // Destroy the subtitle text
      this.subtitle = null;
    }

    // Resume the scene after clearing all orientation warnings
    if(!this.scene.scene.scene.game.questionOpened){
      this.scene.scene.resume();
    }
  }
}

const globalOrientation = new Orientation('landscape'); // Initialize with default orientation

export default globalOrientation;
