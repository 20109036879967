import Phaser from 'phaser';

class AudioController {
  constructor(scene) {
    if (!scene || !scene.registry || !scene.sound) {
      console.error('Invalid scene provided to AudioController');
      return;
    }
    this.scene = scene;
    this.sounds = {};
    this.music = {};

    // Initialize volumes for each group
    this.volume = {
      sounds: scene.registry.get('volumeSound') || 1,
      music: scene.registry.get('volumeMusic') || 0.5
    };

    // Initialize volume in the scene's registry
    scene.registry.set('volumeSound', this.volume.sounds);
    scene.registry.set('volumeMusic', this.volume.music);
  }

  // Sounds
  addSound(name, allowMultiple = false) {

    const sound = this.scene.sound.add(name, { volume: this.volume.sounds, loop: false });
    sound.allowMultiple = allowMultiple;
    this.sounds[name] = sound;
  }

  playSound(name) {
    const sound = this.sounds[name];
    if (sound) {
      sound.play();
    } else {
      console.error(`Sound "${name}" not found`);
    }
  }

  getVolumeSounds() {
    return this.volume.sounds;
  }

  switchVolumeSounds() {

    this.volume.sounds = this.volume.sounds === 0 ? 1 : 0;
    this.scene.registry.set('volumeSound', this.volume.sounds);

    for (const key in this.sounds) {
      this.sounds[key].setVolume(this.volume.sounds);

    }

    return this.volume.sounds;
  }

  // Music
  addMusic(name) {
    this.music[name] = this.scene.sound.add(name, { volume: this.volume.music, loop: true });
  }

  playMusic(name) {
    const music = this.music[name];
    if (music) {
      music.play();
    } else {
      console.error(`Music "${name}" not found`);
    }
  }

  getVolumeMusic() {
    return this.volume.music;
  }

  switchVolumeMusic() {
    this.volume.music = this.volume.music === 0 ? 0.5 : 0;
    this.scene.registry.set('volumeMusic', this.volume.music);

    for (const key in this.music) {
      this.music[key].setVolume(this.volume.music);
    }

    return this.volume.music;
  }
}

export default AudioController;
