import { Container, Row, Col, Card, Button, Form, Input } from 'reactstrap';
import { AuthContext } from '../AuthContext/AuthContext';
import { useState, useContext,useCallback, useEffect, useRef} from "react";
import './style.css'
import API from '../../utils/API';
import { useNavigate } from 'react-router-dom';

const Setting = () => {
    const navigate = useNavigate();
    const { user, setUser } = useContext(AuthContext);
    const [isEditingName, setIsEditingName] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [username, setUserName] = useState(''); 
    const [newUsername, setNewUserName] = useState(''); 
    const [currentPassword, setCurrentPassword] = useState(''); 
    const [newPassword, setNewPassword] = useState(''); 
    const [confirmPassword, setConfirmPassword] = useState(''); 

    const [email,setEmail] = useState(''); 
    const [subscription] = useState("Premium Plan");
    const [errors, setErrors] = useState({  
        username: null,
        currentPassword:null,
        newPassword:null,
        confirmPassword:null
    })
        
    useEffect(()=>{
        if (user) {
            setUserName(user.username);
            setEmail(user.email);
        }    
    },[user])
    const handleNameEdit = () => {
        console.log(user)
        setNewUserName('');
        setIsEditingName(true);
    };

    const handleNameSave = () => {
        const isValid = newUsername.trim().length >= 3 && newUsername.trim().length<=15;
        if(isValid){
            let data = {username:newUsername}
            API.updateUserName(data).then(res => {
                const updatedUser = {
                    ...user, // Spread the existing user data
                    username: newUsername // Update the username
                };
                // Update the user in AuthContext
                setUser(updatedUser);
                setIsEditingName(false);
                setErrors({...errors,username:null});

            }).catch(err=>{
                console.log(err)
            })
        } else{
            setErrors({username:'Username must be between 3 and 15 characters long.'})
        }
    };

    const handleNameCancel = () => {
        setErrors({...errors,username:null});
        setIsEditingName(false);
    };

    const handleViewSubscription = () => {
        // Add logic to view subscription details
        navigate('/dashboard/subscription');
    };

    const handleChangePassword = () => {
        let errorsTemp = {...errors};
        if(newPassword.length < 6){
            errorsTemp.newPassword='Password must be at least 6 characters long.';
            console.log('Password must be at least 6 characters long.')

        } else{
            errorsTemp.newPassword=null;
        }
        if(confirmPassword !== newPassword){
            errorsTemp.confirmPassword='Passwords do not match.';
            console.log('Passwords do not match.')
        }else{
            errorsTemp.confirmPassword=null;
        }
        console.log(errors)
        if(newPassword.length >= 6 && confirmPassword === newPassword){
            setErrors({  
                username: null,
                currentPassword:null,
                newPassword:null,
                confirmPassword:null
            })    
            API.updatePassword({
                currentPassword:currentPassword,
                newPassword:newPassword,
                confirmPassword:confirmPassword
            }).then(res=>{
                cancelPasswordUpdate();
            }).catch(err=>{
                if (err.response && err.response.status === 400) {
                    // Display the error message from the backend
                    setErrors({...errors, currentPassword:"Password Incorrect"});
                } else {
                    // Handle other types of errors (e.g., network errors)
                    console.log(err);
                }
    
            })
        } else {
            setErrors(errorsTemp);
        }

    };
    const cancelPasswordUpdate =()=>{
        setErrors({  
            username: null,
            currentPassword:null,
            newPassword:null,
            confirmPassword:null
        })
        setConfirmPassword('');
        setNewPassword('');
        setCurrentPassword('');
        setUpdatePassword(false);
    }
    return (
        <Container className="h-100 nunito"  fluid>
            <Row className="h-100">
                <Col lg="12" className="align-self-center">
                    <Card >
                        <section className=' m-2'>
                                <h3>Profile Settings</h3>
                        </section>

                        <hr className="m-0" />
                        {/* Email Section */}
                        <section className="p-3">
                            <Row>
                                <Col xs="4" className="text-right font-weight-bold">
                                Email
                                </Col>

                                <Col xs="6">
                                            <h5 className="mb-0">
                                                {email}
                                            </h5>
                                </Col>
                            </Row>
                        </section>
                        <hr className="m-0" />


                        {/* Name Section */}
                        <section className="p-3">
                            <Row>
                            <Col xs="4" className="d-flex align-items-center justify-content-end font-weight-bold">
                                Username
                            </Col>
                                <Col xs="6" className="d-flex align-items-center justify-content-between">
                                    {isEditingName ? (
                                        <>
                                            <Input   
                                                type="text"
                                                value={newUsername}
                                                onChange={(e) => setNewUserName(e.target.value)}
                                            />
                                            <>
                                            <button className="btn  btn-rounded nav-btn-light-purple navbar-btn ml-2"  onClick={handleNameSave}>
                                                Save
                                            </button>
                                            <button className="btn btn-secondary btn-rounded  navbar-btn" onClick={handleNameCancel}>
                                                Cancel
                                            </button>
                                            </>
                                        </>
                                    ) : (
                                        <div className="d-flex justify-content-between w-100">
                                            <h5 className="mb-0">
                                                {username}
                                            </h5>
                                            <span
                                                className='edit-btn'
                                                onClick={handleNameEdit}
                                            >
                                                <i className="fa-regular fa-pen-to-square"></i>
                                            </span>
                                        </div>
                                    )}                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="4"></Col>
                                <Col xs="6">
                                {errors.username && <p style={{ color: 'red', margin: '5px 0' }}>{errors.username}</p>}
                                </Col>
                            </Row>
                        </section>
                        <hr className="m-0" />
                        {/* Change Password Section */}
                        <section className="p-3">
                                {updatePassword ?
                                <>
                                    <Row>
                                        <Col xs="4" className="text-right font-weight-bold">
                                            Current Password
                                        </Col>
        
                                        <Col xs="4">
                                            <Input   
                                                type="password"
                                                value={currentPassword}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="4"></Col>
                                        <Col xs="6">
                                        {errors.currentPassword && <p style={{ color: 'red', margin: '5px 0' }}>{errors.currentPassword}</p>}
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col xs="4" className="text-right font-weight-bold">
                                            New Password
                                        </Col>
        
                                        <Col xs="4">
                                            <Input   
                                                type="password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="4"></Col>
                                        <Col xs="6">
                                        {errors.newPassword && <p style={{ color: 'red', margin: '5px 0' }}>{errors.newPassword}</p>}
                                        </Col>
                                    </Row>

                                    <br/>
                                    <Row>
                                        <Col xs="4" className="text-right font-weight-bold">
                                            Confirm New Password
                                        </Col>
        
                                        <Col xs="4" className="d-flex align-items-center justify-content-between">
                                        <>
                                            <Input   
                                                type="password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            </>
                                        </Col>
                                        <Col xs="4"> 
                                            <button className="btn  btn-rounded nav-btn-light-purple navbar-btn ml-2"  onClick={handleChangePassword}>
                                                Save
                                            </button>
                                            <button className="btn btn-secondary btn-rounded  navbar-btn" onClick={(cancelPasswordUpdate)}>
                                                Cancel
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="4"></Col>
                                        <Col xs="6">
                                        {errors.confirmPassword && <p style={{ color: 'red', margin: '5px 0' }}>{errors.confirmPassword}</p>}
                                        </Col>
                                    </Row>


                                </>
                            
                                    :
                                <Row>
                                    <Col xs="4" className="text-right font-weight-bold">
                                    Password
                                    </Col>
    
                                    <Col xs="6">
                                    <div className="d-flex justify-content-between w-100">
                                            <h5>
                                                ●●●●●●●●
                                            </h5>
                                            <span className='edit-btn' onClick={()=>setUpdatePassword(true)}>
                                                Change Password
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                }
                        </section>
                        <hr className="m-0" />

                        {/* Subscription Section */}
                        <section className="p-3">
                            <Row>
                                <Col xs="4" className="text-right font-weight-bold">
                                Subscription
                                </Col>

                                <Col xs="6">
                                    <div className="d-flex justify-content-between w-100">
                                        <h5
                                            className="mb-0"
                                            style={{ cursor: 'pointer', color: 'rgba(149,0,254,1)' }}
                                            onClick={handleViewSubscription}
                                        >
                                            {subscription}
                                        </h5>
                                        <span className='edit-btn' onClick={handleViewSubscription}>
                                            Manage
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </section>

                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Setting;
