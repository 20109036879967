import './style.css';
import { useState, useEffect } from "react";

const QRcodeImg = ({ room }) => {
    const [qrCodeUrl, setQrCodeUrl] = useState(null);

    useEffect(() => {
        const generateQRCode = (text) => {
            const baseUrl = 'https://api.qrserver.com/v1/create-qr-code/';
            const websiteUrl = process.env.REACT_APP_BASE_URL;
            const fullUrl = `${websiteUrl}${text}`;
            const params = `?size=300x300&data=${encodeURIComponent(fullUrl)}`;
            return `${baseUrl}${params}`;
        };

        setQrCodeUrl(generateQRCode(room));
    }, [room]);

    return (
        <div>
            {qrCodeUrl && <img className="w-100"src={qrCodeUrl} alt="QR Code" />}
        </div>
    );

};

export default QRcodeImg; 