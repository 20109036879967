import Phaser from 'phaser';
import AudioSwitch from '../components/AudioSwitch';
import globalOrientation from '../classes/utils/Orientation';

class MainMenuState extends Phaser.Scene {
  constructor() {
    super({ key: 'MainMenuState' });

  }
  create() {
    const settings = this.game.settings;
    if (settings.lockOrientation) {
      globalOrientation.init(this); // Reinitialize with the new scene context
    }

    this.game.coins = this.game.storage.getItem('coins', 'int');
    
    const bg = this.add.image(0, 0, 'atlas', 'game_bg');
    bg.setOrigin(0, 0);
    bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);

    this.title = this.add.image(this.cameras.main.centerX, 100, 'menu', 'title');
    this.title.setOrigin(0.5);

    this.bird = this.add.sprite(this.cameras.main.centerX, 350, 'menu', 'bird_0');
    this.bird.setOrigin(0.5);

    this.bird.anims.create({
      key: 'idle',
      frames: this.anims.generateFrameNames('menu', { prefix: 'bird_', start: 0, end: 3 }),
      frameRate: 15,
      repeat: -1
    });

    this.bird.play('idle');

    this.bird.t1 = this.tweens.add({
      targets: this.bird,
      x: '-=10',
      yoyo: true,
      repeat: -1,
      duration: 3000,
      ease: 'Linear'
    });

    this.bird.t2 = this.tweens.add({
      targets: this.bird,
      y: '-=10',
      yoyo: true,
      repeat: -1,
      duration: 5000,
      ease: 'Linear'
    });

    this.play = this.add.sprite(this.cameras.main.centerX, 600, 'menu', 'button_play').setInteractive();
    this.play.setOrigin(0.5);
    this.play.on('pointerdown', () => {
      this.scene.start('GameState');
      this.scene.pause()
      this.game.openQuestion();
    });

    // this.shop = this.add.sprite(this.cameras.main.width - 100, 600, 'menu', 'button_shop').setInteractive();
    // this.shop.setOrigin(0.5);
    // this.shop.on('pointerdown', () => {
    //   this.shop.destroy();
    //   this.prepareShop();
    // });

    new AudioSwitch(this, {
      x: this.cameras.main.width - 40,
      y: 40,
      type: 'music',
      spriteOff: 'icon_music_off',
      spriteOn: 'icon_music_on',
      atlas: 'atlas'
    });

    new AudioSwitch(this, {
      x: this.cameras.main.width - 100,
      y: 40,
      type: 'sound',
      spriteOff: 'icon_sound_off',
      spriteOn: 'icon_sound_on',
      atlas: 'atlas'
    });
  }
  // prepareShop() {
  //   this.bird.t1.stop();
  //   this.bird.t2.stop();
  //   this.tweens.add({
  //     targets: [this.title, this.play],
  //     y: { from: 100, to: -200 },
  //     duration: 200,
  //     ease: 'Linear'
  //   });
  //   this.tweens.add({
  //     targets: this.bird,
  //     x: 2000,
  //     duration: 300,
  //     ease: 'Linear',
  //     onComplete: () => {
  //       this.bird.destroy();
  //       this.title.destroy();
  //       this.play.destroy();
  //       this.showShop();
  //     }
  //   });
  // }

  // showShop() {
  //   const skins = JSON.parse(this.game.storage.getItem('skins'));

  //   const coin = this.add.image(280, 50, 'atlas', 'coin_0').setOrigin(0.5);
  //   const coins = this.add.text(coin.x + 40, coin.y, this.game.coins, {
  //     font: '60px feast',
  //     fill: '#fff',
  //     stroke: '#000',
  //     strokeThickness: 8
  //   }).setOrigin(0, 0.5);

  //   for (let i = 0; i < 15; i++) {
  //     const bg = this.add.image(262 + 68 + (i % 5) * 155, 100 + 48 + Math.floor(i / 5) * 145, 'menu', 'skin_bg').setOrigin(0.5);
  //     this.add.image(bg.x, bg.y, 'atlas', `birds/${i}/bird_0`).setOrigin(0.5);

  //     if (skins[i].unlocked) {
  //       const btn = this.add.sprite(bg.x, bg.y + 50, 'menu', 'button_buy_on').setInteractive().setOrigin(0.5);
  //       btn.on('pointerdown', () => {
  //         this.game.skin = btn.index;
  //         this.game.storage.setItem('currentSkin', this.game.skin);
  //         this.scene.start('GameState');
  //       });

  //       const text = this.add.text(btn.x, btn.y, 'Select', {
  //         font: 'feast',
  //         fill: '#fff',
  //         stroke: '#d16207',
  //         strokeThickness: 3
  //       }).setOrigin(0.5);
  //     } else {
  //       const btn = this.add.sprite(bg.x, bg.y + 50, skins[i].price > this.game.coins ? 'menu' : 'menu', skins[i].price > this.game.coins ? 'button_buy_off' : 'button_buy_on').setOrigin(0.5);
        
  //       if (skins[i].price <= this.game.coins) {
  //         btn.setInteractive();
  //         btn.on('pointerdown', () => {
  //           this.game.coins -= skins[btn.index].price;
  //           skins[btn.index].unlocked = true;
  //           this.game.skin = btn.index;
  //           this.game.storage.setItem('skins', JSON.stringify(skins));
  //           this.game.storage.setItem('currentSkin', btn.index);
  //           this.game.storage.setItem('coins', this.game.coins);
  //           this.scene.start('GameState');
  //         });
  //       }

  //       const text = this.add.text(btn.x + 20, btn.y, skins[i].price, {
  //         font: 'feast',
  //         fill: '#fff',
  //         stroke: '#3d3d3d',
  //         strokeThickness: 3
  //       }).setOrigin(0.5);

  //       if (skins[i].price > this.game.coins) {
  //         this.add.image(btn.x - 40, btn.y + 2, 'atlas', 'coin_0').setOrigin(0.5);
  //       }
  //     }
  //   }
  // }
}

export default MainMenuState;
