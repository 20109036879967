import React from 'react';
import "./style.css";
import { Card, Container, Row, Col } from 'reactstrap';

const FinalScoreWaiting = (props) => {
    return (
            <Container className="h-100" fluid>
                <Row className="h-100">
                    <Col xs="2"></Col>
                    <Col xs="8">
                        <Card className="text-center titan-one-regular">
                            <Container>
                                <Row>
                                    <Col >
                                    <h3 className="loading-dots">Loading Final score, Please wait</h3>
                                    </Col>
                                </Row>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </Container>
    );
};

// Define default props to handle cases where props might be undefined

export default FinalScoreWaiting;
