import Phaser from 'phaser';
import Ground from '../classes/obstacles/Ground';
import Bird from '../classes/Bird';
import Spawner from '../classes/Spawner';
import Pipe from '../classes/obstacles/Pipe';
import AudioSwitch from '../components/AudioSwitch';
import globalOrientation from '../classes/utils/Orientation';

class GameState extends Phaser.Scene {
  constructor() {
    super({ key: 'GameState' });
  }
  create() {
    const settings = this.game.settings;
    if (settings.lockOrientation) {
      globalOrientation.init(this); // Reinitialize with the new scene context
    }
    this.speed = 5;
    this.currentSkin = this.game.registry.get('currentSkin');

    this.groups = {};
    ['bg', 'obstacles', 'coins', 'gui'].forEach(group => {
      this.groups[group] = this.add.group({ runChildUpdate: true });
    });

    this.physics.world.setBounds(0, 0, this.scale.width, this.scale.height);

    const bg = this.add.image(0, 0, 'atlas', 'game_bg');
    bg.setOrigin(0, 0);
    bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);

    this.ground = new Ground(this);
    this.ground.isGround=true;
    this.groups.obstacles.add(this.ground);
    // Set up the bird
    // this.bird = new Bird(this, 100, 100, 'birdSprite'); // Adjust parameters as needed
    this.groups.obstacles.add(this.bird = new Bird(this, 100, 100, 'birdSprite'))
    
    // Add collision detection between the bird and the ground
    // this.physics.add.collider(this.bird, this.ground);


    this.spawner = new Spawner(this);

    this.physics.add.collider(this.bird, this.groups.obstacles, this.bird.crash, null, this.bird);

    if(!this.score){
      this.score=0
    }

    this.iconCoins = this.add.image(60, 55, 'atlas', 'coin_0');
    this.groups.gui.add(this.iconCoins);
    this.textCoins = this.add.text(95, 31, this.score, {
      fontFamily: 'Titan One',
      fontSize: '40px',
      fill: '#fff',
      stroke: '#000',
      strokeThickness: 6
    });
    this.groups.gui.add(this.textCoins);

  // Set up audio switches
  this.audioSwitchMusic = new AudioSwitch(this, {
    x: this.cameras.main.width - 40,
    y: 40,
    type: 'music',
    spriteOff: 'icon_music_off',
    spriteOn: 'icon_music_on',
    atlas: 'atlas'
  });

  this.audioSwitchSound = new AudioSwitch(this, {
    x: this.cameras.main.width - 100,
    y: 40,
    type: 'sound',
    spriteOff: 'icon_sound_off',
    spriteOn: 'icon_sound_on',
    atlas: 'atlas'
  });

  // Add audio switches to the 'gui' group
  this.groups.gui.add(this.audioSwitchMusic);
  this.groups.gui.add(this.audioSwitchSound);
    // Pause the game immediately after creation
  if(!this.started){
    this.scene.pause(); // Pause the scene immediately after creation

    // Notify React that the question modal should open
    if (this.game.openQuestion) {
      this.game.openQuestion(); // Call the function passed from React to open the modal
      this.started=true;
    }
  }

  }
  handleCollision(bird, obstacle) {
    // Handle collision logic here
  }
  render() {
    // Optional: debug rendering can be added here
  }

  update() {
    
      // Check for overlap between bird and coins
    // Check for overlap between bird and coins
    this.physics.overlap(this.bird, this.groups.coins, this.bird.collect, null, this.bird);
    }
    

  spawnPipe() {
    new Pipe(this, 1400, 300); // Adjust parameters as needed
    this.time.addEvent({
      delay: 2000,
      callback: this.spawnPipe,
      callbackScope: this,
      loop: true
    });
  }
}

export default GameState;
