import Phaser from 'phaser';
import Coin from '../Coin'; // Adjust the path to where your Coin class is located

class Pipe extends Phaser.GameObjects.Container {
  constructor(scene, x) {
    super(scene, x, 0);

    this.scene = scene;
    this.blocks = [];
    this.bodies = [];

    // Add the container to the scene
    scene.add.existing(this);
    scene.physics.world.enable(this);

    this.speed = scene.speed; // Access scene speed
    this.bottomBlocksNumber = Phaser.Math.Between(0, 7);

    this.generateBlocks(this.bottomBlocksNumber, 'bottom');
    this.generateBlocks(7 - this.bottomBlocksNumber);

    // Add the pipe to the obstacles group
    scene.groups.obstacles.add(this);

    // Set up collision handlers for each body
    this.bodies.forEach(body => {
      // this.scene.physics.add.collider(body, scene.bird, this.destroyAllBlocks, null, this);
      this.scene.physics.add.collider(body, scene.bird, this.destroyAllBlocks, null, this);
    });
  }

  generateBlocks(blocksNumber, side) {
    if (side === 'bottom' && blocksNumber === 0) {
      return this.generateCoins(this.scene.cameras.main.height - 279);
    }

    const yy = side === 'bottom'
      ? this.scene.cameras.main.height - 178
      : (this.scene.cameras.main.height - 178) - ((7 - blocksNumber) * 41 + 48) - 180;

    const bottom = this.scene.add.sprite(this.x, yy - 10, 'atlas', 'pipe_bottom');
    this.blocks.push(bottom);

    for (let i = 0; i < blocksNumber; i++) {
      const middle = this.scene.add.sprite(this.x, (bottom.y - 32) - i * 41, 'atlas', 'pipe_middle');
      this.blocks.push(middle);
    }

    const top = this.scene.add.sprite(this.x, yy - (34 + (blocksNumber * 41)), 'atlas', 'pipe_top');
    // this.scene.groups.obstacles.add(top);
    this.blocks.push(top);

    const height = blocksNumber * 41 + 48;

    const body = this.scene.physics.add.sprite(this.x + 5, (bottom.y + 12) - height / 2, ''); // Create a dynamic body
    body.body.setSize(128, height);
    body.body.setVelocityX(-this.speed * 60); // Apply velocity
    body.body.setAllowGravity(false);
    body.setAlpha(0); // Make the sprite invisible
    this.bodies.push(body);

    if (side === 'bottom') this.generateCoins(top.y - 100);
  }

  generateCoins(yy) {
    const coins = Phaser.Math.Between(1, 3);
    const width = 58;
    const xx = this.x - (coins - 1) * 0.5 * width;

    for (let i = 0; i < coins; i++) {
      this.scene.groups.coins.add(new Coin(this.scene, xx + i * width, yy));
    }
  }

  destroyAllBlocks(body1,body2) {
    body2.crash(body2,body1)
    this.blocks.forEach(block => block.destroy());
    this.blocks = []; // Clear the blocks array

    this.bodies.forEach(body => body.destroy());
    this.bodies = []; // Clear the bodies array
    // Optionally destroy the container if you want to remove it completely
    this.destroy();
  }

  update() {
    this.blocks.forEach(block => {
      block.x -= this.speed;
      if (block.x < -block.width) {
        block.destroy();
        this.blocks.splice(this.blocks.indexOf(block), 1);
      }
    });

    this.bodies.forEach(body => {
      // body.x -= this.speed;
      if (body.x < -body.width) {

        body.destroy();
        this.bodies.splice(this.bodies.indexOf(body), 1);
      }
    });
  }
}

export default Pipe;
