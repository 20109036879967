import Phaser from 'phaser';

class Bomb extends Phaser.Physics.Arcade.Sprite {
  constructor(scene, x, y) {
    super(scene, x, y, 'atlas', 'bee_0');
    
    // Add to scene
    scene.add.existing(this);
    scene.physics.add.existing(this);

    // Set up physics properties
    this.setCircle(30);
    this.body.setImmovable(true);
    this.body.setAllowGravity(false);
    
    // Set up animation with a unique key
    const animKey = 'bombIdle';
    if (!scene.anims.exists(animKey)) {
      scene.anims.create({
        key: animKey,
        frames: scene.anims.generateFrameNames('atlas', {
          prefix: 'bomb_',
          start: 0,
          end: 24
        }),
        frameRate: 20,
        repeat: -1
      });
    }
    this.anims.play(animKey);

    // Set velocity
    this.setVelocityX(-scene.speed * 60);
  }

  update() {
    // Sinusoidal motion effect
    this.setVelocityY(Math.sin(this.x / 150) * 100);

    // Destroy if out of bounds
    if (this.x < -this.width) {
      this.destroy();
    }
  }
}

export default Bomb;
