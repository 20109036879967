import React from 'react';
import "./style.css";
import { Card, Container, Row, Col } from 'reactstrap';

const FinalScore = (props) => {
    const users = props.users || []; // Ensure users is an array
    const userAvatar = props.userAvatar || {}; // Ensure userAvatar is an object

    // Sort users by score in descending order
    const sortedUsers = [...users].sort((a, b) => b.score - a.score);

    // Calculate positions
    const usersWithPosition = sortedUsers.map((user, index) => ({
        ...user,
        position: index + 1,
    }));

    return (
            <Container className="h-100" fluid>
                <Row className="h-100">
                    <Col xs="2"></Col>
                    <Col xs="8">
                        <Card className="text-center titan-one-regular">
                            <Container>
                                <Row className='m-2'>
                                    <Col xs="12">
                                        <h4>Final Score</h4>
                                    </Col>
                                </Row>
                                <hr />
                                
                                <Row className="justify-content-center align-items-center mt-4">
                                    {/* #2 */}
                                    <Col xs="3" className="text-center position-relative">
                                        <div className="ribbon-position titan-one-regular">
                                            <span className="ribbon" style={{ backgroundColor: "silver", fontSize: '1.5rem' }}>#2</span>
                                        </div>
                                        {usersWithPosition[1] && (
                                            <>
                                                <img
                                                    src={userAvatar[usersWithPosition[1]?.username]}
                                                    alt={`${usersWithPosition[1]?.username}'s avatar`}
                                                    style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                                                />
                                                <p className="mt-2 mb-0">{usersWithPosition[1]?.username}</p>
                                                <p className="mb-0">{usersWithPosition[1]?.score}</p>
                                            </>
                                        )}
                                    </Col>

                                    {/* #1 */}
                                    <Col xs="6" className="text-center position-relative">
                                        <div className="ribbon-position titan-one-regular">
                                            <span className="ribbon" style={{ backgroundColor: "gold", fontSize: '2rem' }}>#1</span>
                                        </div>
                                        {usersWithPosition[0] && (
                                            <>
                                                <img
                                                    src={userAvatar[usersWithPosition[0]?.username]}
                                                    alt={`${usersWithPosition[0]?.username}'s avatar`}
                                                    style={{ width: '120px', height: '120px', borderRadius: '50%' }}
                                                />
                                                <p className="mt-3 mb-0">{usersWithPosition[0]?.username}</p>
                                                <p className="mb-0">{usersWithPosition[0]?.score}</p>
                                            </>
                                        )}
                                    </Col>

                                    {/* #3 */}
                                    <Col xs="3" className="text-center position-relative">
                                        <div className="ribbon-position titan-one-regular">
                                            <span className="ribbon" style={{ backgroundColor: "brown", fontSize: '1.2rem' }}>#3</span>
                                        </div>
                                        {usersWithPosition[2] && (
                                            <>
                                                <img
                                                    src={userAvatar[usersWithPosition[2]?.username]}
                                                    alt={`${usersWithPosition[2]?.username}'s avatar`}
                                                    style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                                                />
                                                <p className="mt-2 mb-0">{usersWithPosition[2]?.username}</p>
                                                <p className="mb-0">{usersWithPosition[2]?.score}</p>
                                            </>
                                        )}
                                    </Col>
                                </Row>

                                <hr />

                                {/* List remaining players */}
                                <Row className="justify-content-center mt-4">
                                    <Col xs="12">
                                        {usersWithPosition.slice(3).map(user => (
                                            <Row key={user.username} className='user-list align-items-center justify-content-between mt-2'>
                                                <Col xs="2">
                                                    #{user.position}
                                                </Col>
                                                <Col xs="7" className="d-flex align-items-center">
                                                    <img
                                                        src={userAvatar[user.username]}
                                                        alt={`${user.username}'s avatar`}
                                                        style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                                    />
                                                    <p className="ml-3 mb-0">{user.username}</p>
                                                </Col>
                                                <Col xs="3" className="text-right">
                                                    {user.score}
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </Container>
    );
};

// Define default props to handle cases where props might be undefined
FinalScore.defaultProps = {
    users: [],
    userAvatar: {},
};

export default FinalScore;
