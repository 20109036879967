import { BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import { AuthContext } from './components/AuthContext/AuthContext';
import ProtectedRoute from './components/ProtectedRoute/index';

import './App.css';
import './mobile.css';
import Main from './pages/main/main';
import Dashboard from './pages/dashboard/dashboard';
import Explore from './pages/explore/explore'
import Signup from './pages/signup/signup';
import Login from './pages/login/login';
import PasswordReset from './pages/passwordReset/passwordReset'
// import CreateSet from './pages/createSet/createSet';
import EditSet from './pages/editSet/editSet';
import JeopardySetUp from "./pages/jeopardySetUp/jeopardySetUp";
import GameSelect from "./pages/gameSelect/gameSelect";
//games

import Buzzer from './pages/buzzer/buzzer';

import {Nav,NavUser} from './components/Navbar'

import API from "./utils/API";
import { useState, useContext,useCallback, useEffect, useRef} from "react";
import TicTacToe from "./games/tictactoe/tictactoe";
import TreasureHuntSetUp from "./pages/treasureHuntSetUp/treasureHuntSetUp";
import MildyInfuriatedBird from './games/mildyInfuriatedBird/mildyInfuriatedBird'
import BasketballGame from './games/basketball/basketballGame'

import KpGameSelect from "./pages/kpGameSelect/kpGameSelect";
import GameHost from './pages/gameHost/gameHost'
import JoinGame from "./pages/joinGame/joinGame";
import PreviewJeopardy from "./components/previewJeopardy";

const shouldHideNavbar = (pathname) => {
  const staticRoutes = [
      '/buzzer',
      '/runner',
      '/MildyInfuriatedBird',
      '/basketball',
      '/join'
  ];

  const dynamicRoutesPatterns = [
      /^\/join(?:\/\d+)?$/, // Matches "/join" and "/join/:id"
    //   /^\/play\/jeopardy\/\d+$/, // Matches "/play/jeopardy/:id"
      /^\/Kooblo\/play\/(?:tictactoe|treasurehunt)\/\d+$/, // Matches specific Kooblo routes
      /^\/Koobloparty\/play\/(?:gameType\/)?\d+$/, // Matches Koobloparty dynamic routes
    //   /^\/jeopardy\/edit\/\d+$/ // Matches Jeopardy edit routes
  ];

  return staticRoutes.includes(pathname) || dynamicRoutesPatterns.some(pattern => pattern.test(pathname));
};

function App() {
  const { authenticated } = useContext(AuthContext);
  const location = useLocation();
  
  const showNavbar = !shouldHideNavbar(location.pathname);

  return (
      <div>
          {showNavbar && (authenticated ? <NavUser /> : <Nav />)}

          <Routes>
              <Route exact path="/" element={<Main />} />
              <Route exact path="/explore" element={<Explore />} />
              <Route exact path="/dashboard/*" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route exact path="/MildyInfuriatedBird" element={<MildyInfuriatedBird />} />
              <Route exact path="/Basketball" element={<BasketballGame />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/login" element={<Login />} />
              <Route path="/reset-password" element={<PasswordReset />} />
              <Route exact path="/jeopardy/edit/:id" element={<EditSet />} />
              <Route exact path="/play/jeopardy/:id" element={<JeopardySetUp />} />
              <Route exact path="/jeopardy/preview/:id" element={<PreviewJeopardy />} />
              <Route exact path="/Kooblo/play/:id" element={<GameSelect />} />
              <Route exact path="/Kooblo/play/tictactoe/:id" element={<TicTacToe />} />
              <Route exact path="/Kooblo/play/treasurehunt/:id" element={<TreasureHuntSetUp />} />
              <Route exact path="/Koobloparty/play/:id" element={<KpGameSelect />} />
              <Route exact path="/Koobloparty/play/:gameType/:id" element={<GameSelect />} />
              <Route exact path="/koobloparty/host/:gameType/:id" element={<GameHost />} />
              <Route exact path="/join" element={<JoinGame />} />
              <Route exact path="/join/:id" element={<JoinGame />} />
              <Route exact path="/buzzer" element={<Buzzer />} />
              <Route exact path="/buzzer/:id" element={<Buzzer />} />
          </Routes>
      </div>
  );
}

export default App;
