// src/classes/utils/Storage.js
class Storage {
    constructor(prefix) {
      this.prefix = prefix;
    }
  
    getItem(name, type) {
      const item = localStorage.getItem(this.prefix + name);
  
      // return false if no item found in local storage
      if (item === null) return null;
  
      // return item of type
      return this._itemOfType(item, type);
    }
  
    setItem(name, value, type) {
      if (type === 'boolean') {
        value = value ? 1 : 0;
      }
      localStorage.setItem(this.prefix + name, value);
    }
  
    initItem(name, defaultValue, type) {
      let item = localStorage.getItem(this.prefix + name);
      if (item === null) {
        this.setItem(name, defaultValue, type);
        item = defaultValue;
      }
  
      // return item of type
      return this._itemOfType(item, type);
    }
  
    removeItem(name) {
      localStorage.removeItem(this.prefix + name);
    }
  
    _itemOfType(item, type) {
      if (type === 'int') return parseInt(item);
      if (type === 'float') return parseFloat(item);
      if (type === 'boolean') return Boolean(parseInt(item));
      return String(item);
    }
  }
  
  export default Storage;
  