import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card,Container, Col, CardText, Row, CardBody, CardImg} from 'reactstrap';
import Kooblo from '../../assets/images/Kooblo.PNG'
import './style.css'
export function PreviewKoobloParty(props) {
    let correctAnswers = {};
    let question = props.question;
    const { multiAnswers } = question;

    // Handle case where multiAnswers might be null or undefined
    const answersArray = multiAnswers ? Object.values(multiAnswers) : [];

    return (
        <Card className='m-3'>
            <Container fluid>
                <Row>
                    <Col md="4" className="image-col kq-col-4 d-flex align-items-center p-0">
                        <CardImg src={question.image ? question.image : Kooblo} alt="Question Image" className="kp-questions-image-preview" />
                    </Col>
                    <Col md="8" className='p-0'>
                        <CardBody className='p-0'>
                            <Container>
                                <Row className="">
                                    <CardText className="p-2 d-flex align-items-center">
                                        <strong>Question:</strong>
                                        <span className="flex-grow-1 text-center wrapped-text" style={{ marginLeft: '10px' }}>
                                            {question.question}
                                        </span>
                                    </CardText>
                                </Row>
                                <Row className="">
                                    {/* Safely rendering the answers only if answersArray has elements */}
                                    {answersArray.length > 0 ? (
                                        <>
                                            <Col md="6" className="answer-area1 answer-area p-0">
                                                <div className={'align-items-center'} >
                                                    {answersArray[0].correctAnswer === 'true' ?
                                                        <i className="fa-solid fa-check"></i>
                                                        :
                                                        <i className="fa-solid fa-times"></i>
                                                    }
                                                </div>
                                                <div className="kp-answer-container">
                                                    <p className="m-0 p-2">{answersArray[0].answer}</p>
                                                </div>
                                            </Col>
                                            <Col md="6" className="answer-area2 answer-area p-0">
                                                <div className={''} >
                                                    {answersArray[1].correctAnswer === 'true' ?
                                                        <i className="fa-solid fa-check"></i>
                                                        :
                                                        <i className="fa-solid fa-times"></i>
                                                    }
                                                </div>
                                                <div className="kp-answer-container">
                                                    <p className="m-0 p-2">{answersArray[1].answer}</p>
                                                </div>
                                            </Col>
                                            <Col md="6" className="answer-area3 answer-area p-0">
                                                <div className={''} >
                                                    {answersArray[2].correctAnswer === 'true' ?
                                                        <i className="fa-solid fa-check"></i>
                                                        :
                                                        <i className="fa-solid fa-times"></i>
                                                    }
                                                </div>
                                                <div className="kp-answer-container">
                                                    <p className="m-0 p-2">{answersArray[2].answer}</p>
                                                </div>
                                            </Col>
                                            <Col md="6" className="answer-area4 answer-area p-0">
                                                <div className={''} >
                                                    {answersArray[3].correctAnswer === 'true' ?
                                                        <i className="fa-solid fa-check"></i>
                                                        :
                                                        <i className="fa-solid fa-times"></i>
                                                    }
                                                </div>
                                                <div className="kp-answer-container">
                                                    <p className="m-0 p-2">{answersArray[3].answer}</p>
                                                </div>
                                            </Col>
                                        </>
                                    ) : (
                                        <p className="p-2">No answers available.</p>
                                    )}
                                </Row>
                            </Container>
                        </CardBody>
                    </Col>
                </Row>
            </Container>
        </Card>
    )
}
export function PreviewKooblo(props) {
    let question = props.question;
    return(
        <Card className='m-3'>
        <Container fluid>
          <Row>
            <Col md="4" className="image-col kq-col-4 d-flex align-items-center p-0">
              <CardImg src={question.image ? question.image:Kooblo} alt="Question Image" className="kp-questions-image-preview" />
            </Col>
            <Col md="8" className='p-0'>
              <CardBody className='p-0'>
                    <Container>
                        <Row className="text-center">
                                <Col md="6">
                                <CardText className="text-center"><strong>Question:</strong> </CardText>
                                <p>
                                {question.question}
                                </p>
                                </Col>

                                <Col md="6" >
                                <CardText className="text-center"><strong>Answer:</strong> {props.answer}</CardText>
                                <p>
                                {question.answer}
                                </p>
                                </Col>

                        </Row>

                    </Container>
              </CardBody>
            </Col>
          </Row>
        </Container>
</Card>

    )
}
