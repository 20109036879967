import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export default {
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ USER AUTHENTICATION API~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  authenticate: () => {
    return axios.get(`${apiUrl}/authenticate`, { withCredentials: true });
  },
  login: (data) => {
    return axios.post(`${apiUrl}/login`, data, { withCredentials: true });
  },
  // creates a new user
  signUp: (newuser) => {
    return axios.post(`${apiUrl}/signup`, newuser, { withCredentials: true });
  },
  signOut: () => {
    return axios.get(`${apiUrl}/signout`, { withCredentials: true });
  },
  googleAuth: (token) => {
    return axios.post(`${apiUrl}/auth/google/token`, token, { withCredentials: true });
  },
  sendEmailVerification: (email) => {
    return axios.post(`${apiUrl}/api/send-verification-email`, email, { withCredentials: true });
  },
  verifyEmailCode: (data) => {
    return axios.post(`${apiUrl}/api/verify-email`, data, { withCredentials: true });
  },
  sendResetEmail: (data) => {
    return axios.post(`${apiUrl}/api/request-password-reset`, data, { withCredentials: true });
  },
  resetPassword: (data) => {
    return axios.post(`${apiUrl}/api/reset-password`, data, { withCredentials: true });
  },
  updatePassword: (data) => {
    return axios.post(`${apiUrl}/api/update-password`, data, { withCredentials: true });
  },
  updateUserName: (data) => {
    return axios.post(`${apiUrl}/api/update-username`, data, { withCredentials: true });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~QUESTION SET API ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // creates a new question set
  createSet: (questionSet) => {
    return axios.post(`${apiUrl}/createSet`, questionSet, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  },
  // gets all question sets
  getAllSets: (data) => {
    return axios.get(`${apiUrl}/getAllSets`, {
      params: data,
      withCredentials: true
    });
  },
  // gets data for a single set
  getSet: (id) => {
    return axios.get(`${apiUrl}/getSet/${id}`, { withCredentials: true });
  },
  // get all of the USER's question set
  getUserSets: (data) => {
    return axios.get(`${apiUrl}/getUserSets`, {
      params: data,
      withCredentials: true
    });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ CLASSES API~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  getClasses: () => {
    return axios.get(`${apiUrl}/getclasses`, { withCredentials: true });
  },
  getStudents: (id) => {
    return axios.get(`${apiUrl}/getStudents/${id}`, { withCredentials: true });
  },
  addClass: (data) => {
    return axios.post(`${apiUrl}/addclass`, data, { withCredentials: true });
  },
  saveStudents: (data) => {
    return axios.post(`${apiUrl}/saveStudents`, data, { withCredentials: true });
  },
  deleteStudent: (data) => {
    return axios.post(`${apiUrl}/deleteStudent`, data, { withCredentials: true });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~JEOPARDY GAME API~~~~~~~~~~~~~~~~~~~~~~~~~~~
  getJeopardy: (id) => {
    return axios.get(`${apiUrl}/getJeopardy/${id}`, { withCredentials: true });
  },
  createJeopardyRoom: (data) => {
    return axios.post(`${apiUrl}/create-jeopardy-room`, data, { withCredentials: true });
  },
  saveJeopardySettings: (id, data) => {
    return axios.put(`${apiUrl}/jeopardysettings/${id}`, data, { withCredentials: true });
  },
  savePoints: (id, data) => {
    return axios.put(`${apiUrl}/jeopardypoints/${id}`, data, { withCredentials: true });
  },
  saveQuestion: (data) => {
    return axios.put(`${apiUrl}/jeopardyquestions`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~Kooblo Questions API~~~~~~~~~~~~~~~~~~~~~~~~~~~
  getQuestions: (id) => {
    return axios.get(`${apiUrl}/kooblo/question/${id}`, { withCredentials: true });
  },
  getQuestionSet: (id) => {
    return axios.get(`${apiUrl}/kooblo/questionset/${id}`, { withCredentials: true });
  },
  editQuestion: (data) => {
    return axios.post(`${apiUrl}/kooblo/question/edit`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  },
  deleteQuestion: (id) => {
    return axios.delete(`${apiUrl}/kooblo/question/delete/${id}`, { withCredentials: true });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~Kooblo Party Questions API~~~~~~~~~~~~~~~~~~~~~~~~~~~
  editKpQuestion: (data) => {
    return axios.post(`${apiUrl}/koobloparty/question/edit`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  }
};
// export default {

// // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ USER ATHENTICATION API~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//   authenticate: ()=>{
//     return axios.get("/authenticate", ()=>{
//     })
//   },
//   login: (data)=>{
//     return axios.post("/login", data)
//   },
//   // creates a new user
//   signUp: (newuser) =>{
//     return axios.post("/signup", newuser)
//   },
//   signOut: ()=>{
//     return axios.get("/signout")
//   },
//   googleAuth:(token)=>{
//     return axios.post("/auth/google/token", token)
//   },
//   sendEmailVerification:(email)=>{
//     return axios.post("/api/send-verification-email", email)
//   },
//   verifyEmailCode:(data)=>{
//     return axios.post("/api/verify-email", data)
//   },
//   sendResetEmail:(data)=>{
//     return axios.post("/api/request-password-reset", data)
//   },
//   resetPassword:(data)=>{
//     return axios.post("/api/reset-password", data)
//   },
//   updatePassword:(data)=>{
//     return axios.post("/api/update-password", data)
//   },
//   updateUserName:(data)=>{
//     return axios.post("/api/update-username", data)
//   },

//   //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~QUESTION SET API ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//   //creates a new question set
//   createSet: questionSet => {
//     return axios.post("/createSet", questionSet, {
//       headers: {
//         "Content-Type": "multipart/form-data"
//       }
//     })
//   },
//   //gets all question sets
//   getAllSets:(data)=>{
//     return axios.get("/getAllSets", {
//       params: data
//   });
//   },
//   //gets data for a single set
//   getSet:(id)=>{
//     return axios.get("/getSet/"+ id)
//   },
//   //get all of the USERS question set
//   getUserSets: (data) => {
//     return axios.get("/getUserSets", {
//         params: data
//     });
// },
//   //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ CLASSES API~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//   getClasses:()=>{
//     return axios.get("/getclasses");
//   },
//   getStudents:(id)=>{
//     return axios.get("/getStudents/"+id);
//   },
//   addClass:(data)=>{
//     return axios.post("/addclass", data);
//   },
//   saveStudents:(data)=>{
//     return axios.post("/saveStudents", data);
//   },
//   deleteStudent:(data)=>{
//     return axios.post("/deleteStudent", data);
//   },


//   //~~~~~~~~~~~~~~~~~~~~~~~~JEOPARDY GAME API~~~~~~~~~~~~~~~~~~~~~~~~~~~
//   getJeopardy:(id)=>{
//     return axios.get("/getJeopardy/"+ id);
//   },
//   createJeopardyRoom:(data)=>{
//     return axios.post("/create-jeopardy-room",data)
//   },

//   saveJeopardySettings:(id,data)=>{
//     return axios.put("/jeopardysettings/"+id ,data);
//   },
//   savePoints:(id,data)=>{
//     return axios.put("/jeopardypoints/"+id ,data);
//   },
//   saveQuestion:(data)=>{
//     return axios.put("/jeopardyquestions",data, {
//       headers: {
//         "Content-Type": "multipart/form-data"
//       }
//     })
//   },
//   //~~~~~~~~~~~~~~~~~~~~~~~~Kooblo Questions API~~~~~~~~~~~~~~~~~~~~~~~~~~~
//   getQuestions:(id)=>{
//     return axios.get('/kooblo/question/'+ id);
//   },
//   getQuestionSet:(id)=>{
//     "getting questionset"
//     return axios.get('/kooblo/questionset/'+ id);
//   },

//   editQuestion:(data)=>{
//     return axios.post('/kooblo/question/edit',data, {
//       headers: {
//         "Content-Type": "multipart/form-data"
//       }
//     });
//   },
//   deleteQuestion:(id)=>{
//     return axios.delete('/kooblo/question/delete/'+ id);
//   },
//   //~~~~~~~~~~~~~~~~~~~~~~~~Kooblo Party Questions API~~~~~~~~~~~~~~~~~~~~~~~~~~~
  
//     editKpQuestion:(data)=>{
//       return axios.post('/koobloparty/question/edit',data, {
//         headers: {
//           "Content-Type": "multipart/form-data"
//         }
//       });
//     }

// };
