import { useState, useContext,useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Input, Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, Button } from 'reactstrap';
import './style.css';
import KoobloQuestion from "../KoobloQuestion";
import API from "../../utils/API";
const EditKooblo = (props) => {
    const {id} = useParams();
    const [questions, setQuestions] = useState([]);
    
    useEffect(()=>{
      API.getQuestions(id).then(res=>{
        setQuestions(res.data)
        console.log(res)
      }).catch(err=>{
        console.log(err)
      })
    },[])
    const onAddQuestionClick = () => {
      const newQuestion = {
        question: '',
        answer: '',
        QuestionSetId:id
      };
      console.log(newQuestion);
      API.editQuestion(newQuestion).then(res=>{
        setQuestions(prevQuestions => [...prevQuestions, res.data[0]]);
      }).catch(err=>{
        console.log(err);
      })
      // Create a new array with the new question added
    };
    const onDeleteClick =(id)=>{
      // API.deleteQuestion(id)
      API.deleteQuestion(id)
      .then(response => {
        console.log(response); // Optional: log the response
        const newQuestions = questions.filter(question => question.id !== id);
        setQuestions(newQuestions);
      })
      .catch(error => {
        console.error('Failed to delete question:', error);
      });

      // const newQuestions = questions.filter(question => question.id !== id);
      // setQuestions(newQuestions);
      
      
  }
  return (
        <Container>
          {questions.map((question, index) => (
                    <Row className="m-3">
                    <Col lg="12"className="align-self-center">
                        <KoobloQuestion
                        key={question.id} // Ensure each component has a unique key
                        question={question}
                        onDeleteClick={()=>onDeleteClick(question.id)}
                        />
                    </Col>
                    </Row>

                ))}

            <Row className="m-3">

                    <Col lg="12"className="align-self-center">
                        <Card className={"kq-card-container m-0 text-center nunito justify-content-center add-class-card nunito "} onClick={onAddQuestionClick} >
                                    <p className="text-center card-title"><i class="fa-solid fa-plus"></i> Add Question</p>
                        </Card>

                    </Col>
            </Row>
        </Container>


  );
};

export default EditKooblo; 