import React, { Component } from "react";
import GameCard from "../../components/GameCard";
import API from "../../utils/API";
import { useState, useContext,useCallback, useEffect } from "react";
import { Input, Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, Button, 
    CardFooter} from 'reactstrap';
    import { BrowserRouter as Router, Route, Routes, useLocation, Link} from "react-router-dom";
import CreateSet from '../../components/CreateSet';
import MyGames from '../../components/MyGames';
import AllGames from '../../components/AllGames';
import Class from '../../components/Class';
import Setting from '../../components/Setting';
import EditKooblo from "../../components/EditKooblo";
import EditKoobloParty from "../../components/EditKoobloParty";

//state 
const Explore = () => {
        
    
        return (
            <div className="background-blue-95vh">
                <div className="main-content">
                <AllGames/>

                </div>
            </div>

        )
    
}

export default Explore;