import React, { useState, useContext, useEffect } from 'react';
import API from "../../utils/API";
import { Container, Row, Col, Card,Label,Input, CardImg, CardText, CardBody,
    CardTitle, Button } from 'reactstrap';
import { AuthContext } from '../../components/AuthContext/AuthContext';
import { Link,useNavigate} from 'react-router-dom'; // Import Link for navigation

import './style.css';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login,authenicated } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize useNavigate

  const [validation, setValidation] =useState({
    password:false,
    email: false
  });

  const [errors, setErrors] = useState({  
    email: null,
    password: null,
    resetEmail:null,
  });
  const [messages, setMessages] = useState({  
    email: null,
    password: null,
    resetEmail:null,
  })
  const [isResendVisible, setIsResendVisible] = useState(false);
  const[resetEmail, setResetEmail] = useState('');
  const [showResetPassword,setShowResetPassword] = useState(false);
  const [isButtonDisabled,setIsButtonDisabled] = useState(false)
  const  handleInputChange  = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      validateEmail(value);
      setEmail(value);
    } else if (name === 'password') {
      validatePassword(value)
      setPassword(value);
    } else if(name === 'resetEmail') {
      validateResetEmail(value);
      setResetEmail(value);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrors({})
    if (validation.email && validation.password) {
      setErrors({});
      API.login({ email, password })
        .then(res => {
          if (res.data) {
            navigate('/dashboard')   
              login(res.data); // Call login from AuthContext to set authenticated state
          } else {
            // Handle error if needed, e.g., show a message
            setErrors({password:'Invalid Email or Password'});
          }
        })
        .catch(err => setErrors({password:'Invalid Email or Password'}));
    } else {
      let newErrors = {}
      if(!validation.email){
        newErrors.email =  "Please enter a valid email address";
      }
      if(!validation.password){
        newErrors.password =  "Password must be at least 6 characters long.";
      }
      setErrors(newErrors);
    }
  };
  
  const handleResetPasswordRequest = (e)=>{
    if (!resetEmail) {
      setErrors((prev) => ({
        ...prev,
        resetEmail: "Please enter your email."
      }));
      return;
    }
    let newErrors = {}
    if(!validation.resetEmail){
      newErrors.resetEmail =  "Please enter a valid email address";
      setErrors(newErrors);
      return
    }
    setIsButtonDisabled(true);
    setErrors({});
     API.sendResetEmail({ email: resetEmail }).then(res=>{
      if (res.data.success) {
        setMessages({ passwordReset: 'A reset link has been sent to your email.' });
      } else {
        setErrors({ resetEmail: 'Failed to send reset link. Please try again.' });
      }

     }).catch(err=>{
      console.error(err);
      setErrors({ resetEmail: err.response.data });

     }).finally(()=>{
      setIsResendVisible(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 2000) // 60 seconds
     })
  }
  useEffect(() => {
    const initializeGoogleSignUp = () => {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse
        });

        window.google.accounts.id.renderButton(
          document.getElementById("g_id_button"),
          { theme: "outline", size: "large" }  // Customize button appearance
        );

        window.google.accounts.id.prompt(); // Show the One Tap UI
      } else {
        console.error("Google Identity Services not loaded.");
      }
    };

    // Function to dynamically load Google Identity Services script
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        initializeGoogleSignUp();
      };
      document.body.appendChild(script);
    };

    loadGoogleScript();
  }, []);

  // Handle the credential response
  const handleCredentialResponse = (response) => {
    const idToken = response.credential;
    API.googleAuth({idToken}).then(res=>{
      console.log(res.data)
      login(res.data.user); // Call login from AuthContext to set authenticated state
      navigate('/dashboard')   
    }).catch(err=>{
          console.log(err)

    })
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidation((prev) => ({
      ...prev,
      email: emailRegex.test(email)
    }));
  };
  const validateResetEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidation((prev) => ({
      ...prev,
      resetEmail: emailRegex.test(email)
    }));
  };
  const validatePassword = (password) => {
    // Password should be at least 6 characters long
    const isValid = password.length >= 6;
    setValidation((prev) => ({
      ...prev,
      password: isValid
    }));
  };

  return (
    <div className="main-background vertical-center">
      <Container>
        <Row className="h-100">
          <Col lg="3"></Col>
          <Col lg="6" className="align-self-center">
          {showResetPassword?
          <Card>
            <Container>
              <h3 className="text-align-center titan-one-regular">Reset Password</h3>
              <Row className=''>
                <Col>
                  <Label className='titan-one-regular' style={{marginLeft:'6px'}}>Email:</Label>
                  <Input
                    type="text"
                    value={resetEmail}
                    onChange={handleInputChange}
                    name="resetEmail"
                    placeholder="Enter your email"
                  />
                    {errors.resetEmail && <p style={{ color: 'red' }}>{errors.resetEmail}</p>}
                    {messages.passwordReset && <p style={{ color: 'green' }}>{messages.passwordReset}</p>}

                  <button
                    type="submit"
                    className="btn btn-rounded nav-btn-light-purple signup-button titan-one-small"
                    style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}
                    onClick={handleResetPasswordRequest}
                    disabled={isButtonDisabled}
                  >
                    Send Reset Link
                  </button>
                  {isResendVisible && (
                      <p>
                        Didn't receive the email?{' '}
                        <a href="#" onClick={handleResetPasswordRequest}>
                          Resend
                        </a>
                      </p>
                  )}

                </Col>
              </Row>
            </Container>
          </Card>
          :
            <Card>
              <Container>
                <h3 className="text-align-center titan-one-regular">Login</h3>
                {/* Google Sign-In Button */}
                <Row className='text-center'>
                  <Col>
                  <div style={{display:'inline-block'}}>
                      <div id="g_id_button"></div>
                      </div>

                  </Col>
                  
                    </Row>
                  {/* Divider Line with "or" */}
                  <Row className='text-center'>
                    <Col>
                      <div style={{ position: 'relative', margin: '20px 0' }}>
                        <hr style={{ borderColor: '#ddd' }} />
                        <span className='nunito'style={{ position: 'absolute', left: '50%', top: '-10px', background: '#fff', padding: '0 10px', transform: 'translateX(-50%)', color:'#ddd' }}>or</span>
                      </div>
                    </Col>
                  </Row>


                  <Label className='titan-one-regular' style={{marginLeft:'6px'}}>Email:</Label>
                  <Input
                    type="text"
                    value={email}
                    onChange={handleInputChange}
                    name="email"
                    placeholder="Email"
                  />
                  {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                  <Label className='titan-one-regular' style={{marginLeft:'6px',marginTop:'6px'}}>Password:</Label>
                  <Input
                    type="password"
                    value={password}
                    onChange={handleInputChange}
                    name="password"
                    placeholder="Password"
                  />
                  {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                  <Row style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <Col xs='auto'>
                          <button
                            type="submit"
                            className="btn btn-rounded nav-btn-light-blue signup-button titan-one-small"
                            onClick={handleFormSubmit}
                          >
                          Login
                          </button>
                        </Col>
                        <Col xs='auto'>
                          <p style={{ marginLeft: '20px', marginBottom: '0px' }}>
                            <a href="#" onClick={() => setShowResetPassword(true)}>Forgot Password?</a>
                          </p>
                        </Col>
                  </Row>
              </Container>
            </Card>
            }
          </Col>
          <Col lg="3"></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
