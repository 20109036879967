import Phaser from 'phaser';

class RotatingSpikes extends Phaser.Physics.Arcade.Sprite {
  constructor(scene, x, y) {
    super(scene, x, y, 'atlas', 'rotating_spikes');

    // Add the sprite to the scene and the physics system
    scene.add.existing(this);
    scene.physics.add.existing(this);

    // Set the origin to the center for proper rotation
    this.setOrigin(0.5, 0.1); // x = 0.5 for horizontal center, y = 0 for top

    // Configure physics properties
    this.setImmovable(true); // Ensure it doesn't move due to collisions
    this.body.setAllowGravity(false);


    // Apply velocity and rotation properties
    this.body.setVelocityX(-300); // Move the coin leftward
    this.angularVelocity = 0.5; // Adjust for desired spinning speed

    this.body.angle= 30
    // Store a reference to the scene
    this.scene = scene;
    console.log('Trap spawned at:', x, y);

  }

  update() {

    this.rotation += this.angularVelocity * this.scene.game.loop.delta / 1000;
  
    // Remove spikes if they go out of the screen
    if (this.x < -this.displayWidth) {
      this.dispose();
    }
  }

  dispose() {
    // Clean up
    this.destroy();
  }
}

export default RotatingSpikes;
