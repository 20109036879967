import React, { useState } from 'react';
import "./style.css";
import {  Card, Container, Row, Col } from 'reactstrap';
import QRcodeImg from '../QRcodeImg';
import { useRef} from 'react';

const WaitingRoom = (props) => {
    const { users, userAvatar } = props;
    const [copied, setCopied] = useState(false);
    const [copiedPosition, setCopiedPosition] = useState({ top: 0, left: 0 });
    const websiteUrl = process.env.REACT_APP_BASE_URL;
    const linkRef = useRef(null); // Create a ref for the link container

    const handleCopyLink = (event) => {
        const link = `${websiteUrl}/join/${props.roomId}`;
        navigator.clipboard.writeText(link).then(() => {
            if (linkRef.current) {
                const rect = linkRef.current.getBoundingClientRect();
                const { clientX, clientY } = event;
                
                // Calculate the position of the bubble relative to the container
                const top = clientY - rect.top;
                const left = clientX - rect.left;

                setCopiedPosition({ top: top + window.scrollY, left: left + window.scrollX });
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000); // Hide the "Copied" message after 2 seconds
            }
        }).catch(err => {
            console.error('Failed to copy the link: ', err);
        });
    };
    
  return (
    <div className="background-blue-95vh vertical-center waiting-room">

    <Container className="h-100">
        <Row className="h-100">
            <Col xs="3">
            </Col>
            <Col xs="6">
                <Card className="text-center titan-one-regular">
                    {/* team select counter */}
                    <Container className="">
                        <Row className="justify-content-center align-items-center ">
                            <Col xs="6">              
                               <h3 className="mb-3 ">Join Room  {props.roomId}</h3>
                               <div className="copy-link-wrapper" ref={linkRef}>
                                    <p
                                        className="copy-link"
                                        onClick={handleCopyLink}
                                        style={{ cursor: 'pointer', color: 'rgb(149, 0, 254)', textDecoration: 'underline' }}
                                    >
                                        Click to copy a link
                                    </p>
                                    <span
                                        className={`copied-bubble ${!copied ? 'hidden' : ''}`}
                                        style={{ top: copiedPosition.top, left: copiedPosition.left }}
                                    >
                                        Copied!
                                    </span>
                                </div>
                            </Col>
                            <Col xs="6" className="mb-3 p-4">
                                <QRcodeImg
                                    room={props.qrCodeUrl}
                                />
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                            <Col xs="12">
                                <h4>Players Joined</h4>
                            </Col>
                        </Row>
                        <br></br>
                        {props.errors && <p className='text-danger'>{props.errors}</p>}
                        <Row className='user-list justify-content-center'>
                            {users.map(user => (
                                <Col xs="auto" className="d-flex flex-column align-items-center m-2" key={user.username}>
                                <div className="text-center">
                                    <img src={userAvatar[user.username]} alt={`${user.username}'s avatar`} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                    <p>{user.username}</p>
                                </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <button onClick={props.onGameStart} className="btn btn-rounded nav-btn-light-blue navbar-btn">Start</button>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
);
};

// Define default props to handle cases where props might be undefined

export default WaitingRoom;