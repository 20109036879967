import React, { useState } from 'react';
import { Container, Row, Col, Card, Label, Input, Button } from 'reactstrap';
import API from "../../utils/API";
import './style.css';

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({
    newPassword:false,
    confirmPassword:false
  });
  const [success, setSuccess] = useState(false);
  const [validation, setValidation] =useState({
    password:false,
    confirmPassword: false
  });

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    if (name === 'newPassword') {
        validatePassword(value)
        setNewPassword(value);
    } else if (name === 'confirmPassword') {
        validateConfirmPassword(value)
        setConfirmPassword(value);
    }
  };

  const handlePasswordReset = (event) => {
    event.preventDefault();
    if (!validation.password) {
        setErrors({password:'Password must be at least 6 characters long.'});
      return;
    }
    if (!validation.confirmPassword) {
        setErrors({confirmPassword:'Passwords do not match.'});
        return;
    }
    setErrors({});
    // Assuming the token is available in the URL as a query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    console.log(token)
    console.log(newPassword)
    API.resetPassword({ token, newPassword })
      .then(res => {
        if (res.data.success) {
            setSuccess(true);
        } else {
            setErrors({confirmPassword:'Failed to reset password. Please try again.'});
        }
      })
      .catch(err => {
        console.error(err);
        setErrors({confirmPassword:'An error occurred. Please try again.'});
      });
  };
  const validatePassword = (password) => {
    // Password should be at least 6 characters long
    const isValid = password.length >= 6;
    setValidation((prev) => ({
      ...prev,
      password: isValid
    }));
  };

  const validateConfirmPassword = (confirmPassword) => {
    // Confirm password should match password
    const isValid = confirmPassword === newPassword;
    setValidation((prev) => ({
      ...prev,
      confirmPassword: isValid
    }));
  };

  return (
    <div className="main-background vertical-center">
      <Container>
        <Row className="h-100">
          <Col lg="3"></Col>
          <Col lg="6" className="align-self-center">
            <Card>
              <Container>
                <h3 className="text-align-center titan-one-regular m-3">Reset Password</h3>
                {success ? (
                  <p>Password reset successfully. You can now <a href="/login">login</a> with your new password.</p>
                ) : (
                  < >
                    <Row>
                      <Col>
                        <Label className='titan-one-regular' style={{ marginLeft: '6px' }}>New Password:</Label>
                        <Input
                          type="password"
                          value={newPassword}
                          onChange={handlePasswordChange}
                          name="newPassword"
                          placeholder="Enter your new password"
                        />
                      </Col>
                    </Row>
                    {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                    <Row>
                      <Col>
                        <Label className='titan-one-regular' style={{ marginLeft: '6px', marginTop: '6px' }}>Confirm Password:</Label>
                        <Input
                          type="password"
                          value={confirmPassword}
                          onChange={handlePasswordChange}
                          name="confirmPassword"
                          placeholder="Confirm your new password"
                        />
                      </Col>
                    </Row>
                    {errors.confirmPassword && <p style={{ color: 'red' }}>{errors.confirmPassword}</p>}
                    <Row style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                      <Col xs='auto'>
                        <button
                          type="submit"
                          className="btn btn-rounded nav-btn-light-purple signup-button titan-one-small"
                          onClick={handlePasswordReset}
                        >
                          Reset Password
                        </button>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card>
          </Col>
          <Col lg="3"></Col>
        </Row>
      </Container>
    </div>
  );
};

export default PasswordReset;
