import beepNotifSound from './assets/sounds/beep-notif.mp3';
import copperDingSound from './assets/sounds/copper-ding.mp3';
import correctAnswerSound from './assets/sounds/correct-answer.wav';
import countdownSound from './assets/sounds/countdown.wav';
import gameStartCountdownSound from './assets/sounds/game-start-countdown.wav';
import wrongBuzzerSound from './assets/sounds/wrong-buzzer.wav';
import plusPointSound from './assets/sounds/plus-points.mp3';
import minusPointSound from './assets/sounds/minus-points.mp3';

class Sound {
  constructor(src) {
    this.audio = new Audio(src);
  }
  play = () => {
    this.audio.play();
  };

  pause = () => {
    this.audio.pause();
  };

  mute = () => {
    this.audio.muted = true;
  };

  unmute = () => {
    this.audio.muted = false;
  };

  setVolume = (volume) => {
    this.audio.volume = volume; // volume should be between 0 and 1
  };
}

export const beepNotif = new Sound(beepNotifSound);
export const copperDing = new Sound(copperDingSound);
export const correctAnswer = new Sound(correctAnswerSound);
export const countdown = new Sound(countdownSound);
export const gameStartCountdown = new Sound(gameStartCountdownSound);
export const wrongBuzzer = new Sound(wrongBuzzerSound);
export const plusPoint = new Sound(plusPointSound);
export const minusPoint = new Sound(minusPointSound);
