import React from 'react';
import "./style.css";
import { Card, Container, Row, Col } from 'reactstrap';

const HostScoreBoard = (props) => {
    const users = props.users || []; // Ensure users is an array
    const userAvatar = props.userAvatar || {}; // Ensure userAvatar is an object

    // Sort users by score in descending order
    const sortedUsers = [...users].sort((a, b) => b.score - a.score);

    // Calculate positions
    const usersWithPosition = sortedUsers.map((user, index) => ({
        ...user,
        position: index + 1,
    }));

    return (
        <div className="background-blue-95vh vertical-center waiting-room">
            <Container className="h-100" fluid>
                <Row className="h-100">
                    <Col xs="2">
                    </Col>
                    <Col xs="8">
                        <Card className="text-center titan-one-regular">
                            <Container>
                                <Row className='m-2'>
                                <Col xs="4">
                                        <h4>{props.timer}</h4>
                                    </Col>
                                    <Col xs="4">
                                        <h4>Leaderboard</h4>
                                    </Col>
                                    <Col xs="4">
                                        <button onClick={props.onEndGame}>end game</button>
                                    </Col>

                                </Row>
                                <hr />
                                <Row className="justify-content-center " style={{textDecoration:'underline'}}>
                                    <Col xs="4"><strong >Position</strong></Col>
                                    <Col xs="4"><strong>Player</strong></Col>
                                    <Col xs="4"><strong>Score</strong></Col>
                                </Row>
                                    {usersWithPosition.map(user => (
                                            <Row className='user-list align-items-center justify-content-center ' style={{marginTop:'8px'}}>
                                                <Col xs="4" >
                                                    {user.position}
                                                    
                                                </Col>
                                                <Col xs="4" className="d-flex align-items-center justify-content-center">
                                                    <img 
                                                        src={userAvatar[user.username]} 
                                                        alt={`${user.username}'s avatar`} 
                                                        style={{ width: '50px', height: '50px', borderRadius: '50%' }} 
                                                    />
                                                    <p className="ml-2 mb-0">{user.username}</p>
                                                </Col>
                                                <Col xs="4" >
                                                    {user.score}
                                                </Col>
                                            </Row>

                                    ))}
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

// Define default props to handle cases where props might be undefined
HostScoreBoard.defaultProps = {
    users: [],
    userAvatar: {},
};

export default HostScoreBoard;
