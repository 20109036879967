import { useEffect, useRef, useState} from "react";
import { Input, Container, Row, Col, Card, Label, CardText, 
    Modal,  ModalBody, Button} from 'reactstrap';
import './style.css';
import {useParams, useNavigate} from 'react-router-dom';
import basketballPreview from '../../assets/images/basketball.PNG'
import MildyInfuriatedBirdPreview from '../../assets/images/MildyInfuriatedBird.png'
  
const KpGameSelect = () => {
  const navigate = useNavigate();
    const {id} = useParams();    
    const gameData ={
      'MildyInfuriatedBird':{
        name:'Mildy Infuriated Bird',
        description:'Answer correctly to place an X or O on the board. Aim to be the first to align three symbols in a row, column, or diagonal to win.',
        players:'2-30'
      },
      'basketball':{
        name:'Trivia Dunk',
        description:' Answer questions to open treasure chests. The team with the most gold at the end wins!',
        players:'2-6'

      }
    }
  const [selectedGame, setSelectedGame] = useState(null);
    const handleCardClick = (game) => {
      setSelectedGame(game);
    };  

    const startGame=()=>{
      let path;
      if (selectedGame === 'MildyInfuriatedBird') {
        path = `/koobloparty/host/MildyInfuriatedBird/${id}`;
      } else if (selectedGame === 'basketball') {
        path = `/koobloparty/host/basketball/${id}`;
      }
      navigate(path);

    }
  return (

            <div className="background-blue-95vh ">
              <Container className="p-4"fluid>
                <Row>
                      <Col xs="12">
                      <Card className="w-100 text-center game-banner">
                        {selectedGame ? (
                          <Container>
                            <Row>
                              <Col lg="4" className="h-100">
                                <img
                                  src={selectedGame === 'MildyInfuriatedBird' ? MildyInfuriatedBirdPreview : basketballPreview}
                                  alt={selectedGame === 'MildyInfuriatedBird' ? "MildyInfuriatedBird" : "basketball"}
                                  className="game-image"
                                />
                              </Col>
                              <Col lg="4" className="d-flex justify-content-center">
                                <div>
                                  <h3 className="titan-one-regular">{gameData[selectedGame].name}</h3>
                                  <h4 className="nunito">Players: {gameData[selectedGame].players}</h4>
                                  <p className="nunito">{gameData[selectedGame].description}</p>
                                </div>
                              </Col>
                              <Col lg="4" className="d-flex align-items-center justify-content-center">
                                <button className="btn  btn-rounded nav-btn-light-blue navbar-btn" onClick={startGame}>Start Game</button>
                              </Col>
                            </Row>
                          </Container>
                        ) : (
                          <h2 className="titan-one-regular">Select a Game</h2>
                        )}
                      </Card>
                      </Col>
                </Row>
              </Container>
              <Container className="h-100">
                  <Row className="h-100">
                    <Col md="auto" className="mb-4">
                        <img                         
                        onClick={() => handleCardClick('MildyInfuriatedBird')}
                        className={` game-logo ${selectedGame === 'MildyInfuriatedBird' ? 'selected' : ''}`}
                        src={MildyInfuriatedBirdPreview} alt="MildyInfuriatedBird" />
                    </Col>

                    <Col md="auto" className="mb-4 text-center">
                        <img                         
                        onClick={() => handleCardClick('basketball')}
                        className={` game-logo ${selectedGame === 'basketball' ? 'selected' : ''}`}
                        src={basketballPreview} alt="basketball" />
                    </Col>
                  </Row>
                </Container>
            </div>
  );
};

export default KpGameSelect; 