import React from 'react';
import { Container, Row, Col, Card, FormGroup, Label, Input } from 'reactstrap';
import MildyInfuriatedBirdPreview from '../../assets/images/MildyInfuriatedBird.png';

const MildyInfuriatedBirdSettings = ({ questionset, timer, onTimerInputChange, onTimerChange, onCreateRoom }) => {
  return (
    <Container className="h-100">
      <Row className="h-100">
        <Col xs="12">
          <Card className="text-center">
            <Container className="h-100">
              <Row>
                <Col lg="12">
                  <h1 className='titan-one-regular'>Mildy Infuriated Bird</h1>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <img className="w-100" src={MildyInfuriatedBirdPreview} alt="Mildy Infuriated Bird" />
                </Col>
              </Row>
              <br></br>

              <Row className="h-100">
                <Col md="6">
                  <Container className="text-center nunito">
                    <Row className="text-center">
                      <Col xs="12">
                        <h3 className="text-center mb-3">{questionset.title}</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        {questionset.image && (
                          <img className="setup-image mb-3" src={questionset.image} alt="Question Set" />
                        )}
                      </Col>
                      <Col md="6">
                        {questionset.description && (
                          <p className="mb-3">{questionset.description}</p>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs="6" className="mb-3 vertical-center justify-content-center nunito">
                  <Container>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                        <Label htmlFor="timer"className="font-weight-bold">Time (minutes) (3-30 min)</Label>
                        <Input
                            type="number"
                            id="timer"
                            min="3"
                            max="30"
                            value={timer}
                            onChange={onTimerInputChange} // Handle typing input
                            onBlur={onTimerChange} // Validate on blur (when the user finishes)
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <button
                          onClick={onCreateRoom}
                          className="btn btn-rounded nav-btn-light-blue navbar-btn"
                        >
                          Start
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MildyInfuriatedBirdSettings;
