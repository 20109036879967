import Phaser from 'phaser';

class Bee extends Phaser.Physics.Arcade.Sprite {
  constructor(scene, x, y) {
    super(scene, x, y, 'atlas', 'bee_0');
    
    // Add to scene
    scene.add.existing(this);
    scene.physics.add.existing(this);
    // Set up physics properties
    this.body.setSize(this.width-10, this.height-10);
    this.body.setImmovable(true);
    this.body.setAllowGravity(false);
    // Set up animation
    this.anims.create({
      key: 'idle',
      frames: this.anims.generateFrameNames('atlas', {
        prefix: 'bee_',
        start: 0,
        end: 16
      }),
      frameRate: 20,
      repeat: -1
    });
    this.anims.play('idle');

    // Set velocity
    this.setVelocityX(-scene.speed * 60 * 1.5);
  }

  update() {
    // Destroy if out of bounds
    if (this.x < -this.width) {
      this.destroy();
    }
  }
}

export default Bee;
