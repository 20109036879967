import Phaser from 'phaser';

class Ground extends Phaser.GameObjects.TileSprite {
  constructor(scene) {
    // Set initial x and y to 0 and align the sprite at the bottom of the scene
    const width = scene.scale.width;
    const height = 178; // Height of the ground
    super(scene, 0, scene.scale.height - height, width, height, 'atlas', 'ground');

    // Add the ground to the scene
    scene.add.existing(this);

    // Enable physics on the ground
    scene.physics.add.existing(this);

    // Set the ground to be immovable and not affected by gravity
    this.body.setImmovable(true);
    this.body.allowGravity = false;

    // Set the ground's collision properties
    this.body.setSize(width, height);

    // Make sure the ground covers the entire bottom width of the game
    this.setOrigin(0, 0); // Align the ground sprite to the bottom left

    // Store a reference to the scene
    this.scene = scene;
  }

  update() {
    // Move the ground to the left to create a scrolling effect
    this.tilePositionX += this.scene.speed;
  }
}

export default Ground;
