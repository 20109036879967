import React from 'react';
import "./style.css";
import { Card, CardText, CardBody, CardTitle } from 'reactstrap';
import koobloIMG from '../../assets/images/Kooblo.PNG';

const GameCard = ({ 
  photos = koobloIMG, 
  name = 'Default Name', 
  about = 'Default About Text', 
  gametype, 
  questionCount, 
  playCount, 
  onPlayClick, 
  onEditClick, 
  onViewClick,
  id 
}) => {
  playCount = Math.floor(Math.random() * 1000); // Random number between 0 and 999

  const gameTypeBorder = (gameType) => {
    switch (gameType) {
      case 'Jeopardy':
        return 'jeopardy-border';
      case 'Kooblo Party':
        return 'kooblo-party-border';
      case 'Kooblo':
        return 'kooblo-border';
      default:
        return 'kooblo-border';
    }
  };

  const gameTypeTag = (gameType) => {
    switch (gameType) {
      case 'Jeopardy':
        return 'jeopardy-tag';
      case 'Kooblo Party':
        return 'kooblo-party-tag';
      case 'Kooblo':
        return 'kooblo-tag';
      default:
        return 'kooblo-tag';
    }
  };

  return (
    <div className='nunito'>
      <Card className={`card-container ${gameTypeBorder(gametype)}`} key={id}>
        <div className="gamecard-image-body">
          <img className="gamecard-image w-100" src={photos} alt="Game" />
        </div>
        <p className={`titan-one-small ${gameTypeTag(gametype)}`}> {gametype} </p>
        <CardBody className='p-2'>
          <CardTitle className="card-title"> {name} </CardTitle>
          <CardText>{about}</CardText>
        </CardBody>
        <div className="card-info-container d-flex justify-content-between align-items-center p-2">
          <div className="game-info d-flex align-items-center">
            {gametype !== "Jeopardy" && (
              <div>
                <i className="fa-regular fa-circle-question"></i> 
                <small className="ms-2 p-1"> {questionCount} </small>
              </div>
            )}
            <div className='p-2'>
              <i className="fa-regular fa-circle-play ms-4"></i>  
              <small className="ms-2 p-1">{playCount}</small>
            </div>
          </div>
          <div className="author-info d-flex align-items-center">
            <i className="fa-solid fa-feather"></i>  
            <small className="ms-2"> Teacher Jack</small>
          </div>
        </div>
        <div className="class-link-group nunito">
          <p className="class-card-custom-link" onClick={onPlayClick}><i className="fa-solid fa-gamepad"></i></p>
          {onEditClick && <p className="class-card-custom-link" onClick={onEditClick}><i className="fa-solid fa-pen-to-square"></i></p>}
          {onViewClick && <p className="class-card-custom-link" onClick={onViewClick}><i className="fa-solid fa-magnifying-glass"></i></p>}
        </div>
      </Card>
    </div>
  );
};

export default GameCard;
