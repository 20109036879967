import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row} from 'reactstrap';
import './style.css'; // Assuming you have a separate CSS file for styling

function shuffleObject(obj) {
  const valuesArray = Object.values(obj);

  for (let i = valuesArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [valuesArray[i], valuesArray[j]] = [valuesArray[j], valuesArray[i]];
  }

  const shuffledObject = {};
  Object.keys(obj).forEach((key, index) => {
    shuffledObject[key] = valuesArray[index];
  });

  return shuffledObject;
}

const QuizModal = ({ isOpen, onAnswer, question }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [shuffledAnswers, setShuffledAnswers] = useState(null);
  const [canProceed, setCanProceed] = useState(false); // Controls when user can proceed
  const [timer, setTimer] = useState(3); // Countdown timer
  const [initialOverlayVisible, setInitialOverlayVisible] = useState(false); // Initial overlay visibility

  useEffect(() => {
    if (isOpen && question) {
      const shuffled = shuffleObject(question.multiAnswers);
      setShuffledAnswers(shuffled);
      setShowFeedback(false);
      setCanProceed(false); // Reset proceed state
      setTimer(3); // Reset timer
      setInitialOverlayVisible(true); // Show initial overlay

      // Hide initial overlay after 1 second
      const overlayTimeout = setTimeout(() => {
        setInitialOverlayVisible(false);
      }, 500);

      // Cleanup
      return () => clearTimeout(overlayTimeout);

    }
  }, [isOpen, question]);

  useEffect(() => {
    let countdown;
    if (showFeedback && !canProceed && !isCorrect) {  // Timer only when the answer is incorrect
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            setCanProceed(true); 
            clearInterval(countdown);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else if (isCorrect) {
      setCanProceed(true); // Immediately allow proceeding if the answer is correct
    }

    return () => clearInterval(countdown); 
  }, [showFeedback, canProceed]);

  if (!isOpen || !shuffledAnswers) return null;

  const handleAnswer = (correctAnswer) => {
    if(!showFeedback){
      setIsCorrect(correctAnswer === "true");
      setShowFeedback(true); // Reset state for the next question
    }
  };
  const handleProceed = () => {
    if (canProceed) {
      onAnswer(isCorrect ? "true" : "false");
      setIsCorrect(null)
      setShowFeedback(false);
      setCanProceed(false); // Reset state for next question
      setTimer(3); // Reset timer on new question

    }
  };


  const modalClass = showFeedback
    ? isCorrect
      ? 'quiz-modal-content bg-success' // Green for correct answers
      : 'quiz-modal-content bg-danger'  // Red for incorrect answers
    : 'quiz-modal-content';              // Default color when no answer selected


  return (
    <div className="modal-overlay">
      <div className="quiz-modal-dialog">
      <div className={modalClass + ' nunito'}>
      <>
          <Container className='h-50 justify-content-center align-items-center' fluid>
              {/* Ternary logic to display either question & image OR feedback */}
              {showFeedback ? (
                <Row className='h-100 justify-content-center align-items-center'>
                  <Col xs="12" className="d-flex flex-column justify-content-center align-items-center">
                    <h1 className='titan-one-regular'>{isCorrect ? 'Correct!' : 'Incorrect!'}</h1>
                    {canProceed ? (
                      <p>Click to proceed</p>
                    ) : (
                      <p>Wait {timer} second{timer > 1 ? 's' : ''} to proceed</p>
                    )}
                  </Col>
                </Row>
              ) : (
                <Row className='h-100 justify-content-center align-items-center'>
                  {question.image && (
                    <Col xs="12" md="6" className="d-flex justify-content-center align-items-center question-modal-image">
                      <img className="quiz-card-image" src={question.image} alt="Question Image" />
                    </Col>
                  )}
                  <Col xs="12" md="6" className="d-flex flex-column justify-content-center align-items-center question-modal-question">
                    <h3>{question.question}</h3>
                  </Col>
                </Row>
              )}

            </Container>

              {/* row for buttons */}
            <Container className='h-50'fluid>

            <Row className="h-50 text-wrap">
 
                <Col md="6" className='p-1'>
                {
                  showFeedback && shuffledAnswers.answer1.correctAnswer=== "false" ? null : (
                    <button className="btn btn-light-blue button-size kp-question-btn" 
                            onClick={() => handleAnswer(shuffledAnswers.answer1.correctAnswer)}>
                      <h3>{shuffledAnswers.answer1.answer}</h3> 
                    </button>
                  )
                }
                </Col>
                <Col md="6" className='p-1'>
                {
                  showFeedback && shuffledAnswers.answer2.correctAnswer=== "false" ? null : (
                  <button className="btn btn-light-red button-size kp-question-btn" 
                  onClick={() => handleAnswer(shuffledAnswers.answer2.correctAnswer)}>
                  <h3>{shuffledAnswers.answer2.answer}</h3> 
                  </button>
                  )
                }
                </Col>
              </Row>
              <Row className="h-50">

              <Col md="6" className='p-1'>
              {
                  showFeedback && shuffledAnswers.answer3.correctAnswer=== "false" ? null : (
                <button className="btn btn-light-yellow button-size kp-question-btn" 
                onClick={() => handleAnswer(shuffledAnswers.answer3.correctAnswer)}>
                <h3>{shuffledAnswers.answer3.answer}</h3> 
                </button>
                )
              }
              </Col>
              <Col md="6" className='p-1'>
              {
                  showFeedback && shuffledAnswers.answer4.correctAnswer=== "false" ? null : (
              <button className="btn  btn-light-green button-size kp-question-btn" 
              onClick={() => handleAnswer(shuffledAnswers.answer4.correctAnswer)}>
                <h3>{shuffledAnswers.answer4.answer}</h3> 
                </button>
                )
              }
              </Col>
            </Row>
          </Container>
          </>
          {/* Initial invisible overlay */}
            {initialOverlayVisible && (
            <div className="invisible-overlay"></div>
          )}

            {/* Invisible Overlay */}
            {showFeedback && (
              <div 
              className="invisible-overlay" 
              style={{ cursor: canProceed ? 'pointer' : 'default' }}  // Apply pointer cursor when canProceed is true
              onClick={handleProceed}>
              </div>
            )}

          </div>
      </div>
    </div>

  );
};

export default QuizModal;
