// utils.js
import Phaser from 'phaser';

// Generate random integer from 0 to max (inclusive)
export function irandom(max) {
    return Math.floor(Math.random() * (max + 1));
}

// Generate random integer in range [min, max] (inclusive)
export function irandom_range(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Generate random number in range [min, max]
export function random_range(min, max) {
    return Math.random() * (max - min) + min;
}

// Get random array item
export function randomArrayItem(array) {
    return array[irandom(array.length - 1)];
}

// Choose a random item from provided arguments
export function choose(...args) {
    if (args.length === 0) return undefined;
    return args[irandom(args.length - 1)];
}

// Check if two sprites overlap (requires Phaser 3)
export function checkOverlap(spriteA, spriteB) {
    const boundsA = spriteA.getBounds();
    const boundsB = spriteB.getBounds();
    return Phaser.Geom.Rectangle.Overlaps(boundsA, boundsB);
}
