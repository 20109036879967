import Phaser from 'phaser';
import atlas from '../assets/images/atlas.png';
import atlasJson from '../assets/images/atlas.json';
import menu from '../assets/images/menu.png';
import menuJson from '../assets/images/menu.json';
import sndWings from '../assets/audio/wings.mp3';
import sndWingsOgg from '../assets/audio/wings.ogg';
import sndBam from '../assets/audio/bam.mp3';
import sndBamOgg from '../assets/audio/bam.ogg';
import sndCoin from '../assets/audio/coin.mp3';
import sndCoinOgg from '../assets/audio/coin.ogg';
import musicOst from '../assets/audio/ost.mp3';
import musicOstOgg from '../assets/audio/ost.ogg';
import globalOrientation from '../classes/utils/Orientation';

class PreloadState extends Phaser.Scene {
  constructor() {
    super({ key: 'PreloadState' });
  }

  preload() {
    // Show logo and progress bar
    this.preloadBar = this.add.sprite(this.game.scale.width / 2, this.game.scale.height / 2 + 100, 'preload', 'progress');
    this.preloadBar.setOrigin(0.5, 0.5);

    // Update the progress bar on load progress
    this.load.on('progress', (value) => {
      this.preloadBar.setScale(value, 1);
    });

    // Complete progress
    this.load.on('complete', () => {
      this.preloadBar.setScale(1, 1); // Ensure the bar is full when loading completes
    });

    // Load assets
    this.load.atlas('atlas', atlas, atlasJson);
    this.load.atlas('menu', menu, menuJson);
    this.load.audio('sndWings', [sndWings, sndWingsOgg]);
    this.load.audio('sndBam', [sndBam, sndBamOgg]);
    this.load.audio('sndCoin', [sndCoin, sndCoinOgg]);
    this.load.audio('musicOst', [musicOst, musicOstOgg]);
  }

  create() {
    this.game.audio.addSound('sndWings');
    this.game.audio.addSound('sndBam');
    this.game.audio.addSound('sndCoin');
    this.game.audio.addMusic('musicOst');
    this.game.audio.playMusic('musicOst');
    const settings = this.game.settings;
    if (settings.lockOrientation) {
      globalOrientation.init(this); // Reinitialize with the new scene context
    }

    // Start game
    this.scene.start('MainMenuState');
  }
}

export default PreloadState;
