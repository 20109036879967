import React, { Component} from "react";
import API from "../../utils/API";
import { useState, useContext,useCallback, useEffect, useRef} from "react";
import { Input, Container, Row, Col, Card, CardImg, CardText, CardBody, CardTitle, Button, CardFooter, ModalBody, Modal, Label} from 'reactstrap';
import {useParams, useNavigate} from 'react-router-dom';

//state 
const PreviewJeopardy = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [modalToggle, setModalToggle] = useState(false);
    const [points, setPoints] = useState({
        1:"100",
        2:"200",
        3:"300",
        4:"400",
        5:"500",
        6:"600",
        7:"700", 
        8:"800"
    });
    const [questionset, setQuestionSet] = useState({});

    const [currentModal, setCurrentModal] =useState();
    const [categoryNames, setCategoryNames] = useState({});

    const [totalColumns, setTotalColumns ] = useState(6);
    const [totalRows, setTotalRows ] = useState(6);
    const [questions,setQuestions] = useState({});
    //used to show q/a for all fields
    const [showAll, setShowAll] = useState(false);
    const [modalExpand, setModalExpand]= useState(false);
    const [modalCss, setModalCss]= useState();
    const [showAnswer, setShowAnswer] = useState(false);
    const timeoutRef = useRef(null);
    const [showContent, setShowContent] = useState(false);
    const [modalOpened, setModalOpened] = useState({});

    // END varaibles for image input modal

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const onShowAnswer = ()=>{
        setShowAnswer(!showAnswer);
        
      }

    useEffect(()=>{
        API.getJeopardy(id)
            .then(res => { 
                setQuestionSet(res.data)
                let jeopardyData = res.data;
                if(jeopardyData.Jeopardy){
                    setTotalColumns(jeopardyData.Jeopardy.columns);
                    setTotalRows(jeopardyData.Jeopardy.rows);
                    let categoryNamesTemp = {...categoryNames}
                    for(let i = 0; i <= jeopardyData.Jeopardy.columns; i++){
                        if(jeopardyData.Jeopardy[i]){
                            categoryNamesTemp[i]= jeopardyData.Jeopardy[i];
                        }
                    }
                    setCategoryNames(categoryNamesTemp);
                }
                if(jeopardyData.JeopardyPoint){
                    setPoints(jeopardyData.JeopardyPoint);
                }
                if(jeopardyData.JeopardyQuestions){
                    let questionsTemp = {...questions};
                    for(let i =0; i< jeopardyData.JeopardyQuestions.length;i++){
                        let position = jeopardyData.JeopardyQuestions[i].position;
                        if(position){
                            questionsTemp[position]={};
                            questionsTemp[position].question=jeopardyData.JeopardyQuestions[i].question;
                            questionsTemp[position].answer=jeopardyData.JeopardyQuestions[i].answer;
                            questionsTemp[position].image=jeopardyData.JeopardyQuestions[i].image;
                            questionsTemp[position].id=jeopardyData.JeopardyQuestions[i].id;
                        }
                    }
                    setQuestions(questionsTemp)
                }
                    })
            .catch(err => console.log(err));
    },[]);
 
    const togglemodalToggle = () => {
        setModalToggle(!modalToggle)
    };
    const openModal = (e,modalID) =>{
        console.log(modalID)
        console.log(questions)
        setShowContent(false);
        setShowAnswer(false);
        setModalExpand(false);
        setCurrentModal(modalID);
        togglemodalToggle();
        setModalCss({top: e.clientY+"px", left: e.clientX+"px", });
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            setModalExpand(true);
          }, 50);    
        setTimeout(() => {
          setShowContent(true);
      }, 300); // Adjust this timeout to match the CSS transition duration

        let modalOpenedTemp = {...modalOpened};
        modalOpenedTemp[modalID] = true;
        setModalOpened(modalOpenedTemp);
    };
    const startGame = () => {
        navigate('/play/jeopardy/' + id);
    };

        return (
            <div className="game-background position-relative">
                {/* MODAL */}
                {modalToggle ?           
                 <div className="jeopardy-modal-container" style={{zIndex:"10000"}} onClick={e=>{togglemodalToggle()}}>
                    <div className={"jeopardy-modal text-center " + (modalExpand? "jeopardy-modal-expand":"") }style={modalCss} onClick={e=>e.stopPropagation()}>
                    {questions[currentModal]? 
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">

                            {showAnswer?
                              <div className="m-3 nunito">
                                <h1>{questions[currentModal].answer}</h1>
                              </div>
                            :
                              <div>
                                {questions[currentModal].image && <img className="preview-image" src={questions[currentModal].image} alt="Image description" />}
                                <div className="m-3 nunito">
                                  <h1>{questions[currentModal].question}</h1>
                                </div>
                              </div>

                            }

                          <div className="jeopardy-button-container titan-one-small">
                            {showAnswer?
                                <button className="btn show-answer-btn" onClick={()=>onShowAnswer()}>Show Question</button>
                              :
                                <button className="btn show-answer-btn" onClick={()=>onShowAnswer()}>Show Answer</button>
                            }
                          </div>

                        </div>
                    : 
                    <div className="d-flex flex-column justify-content-center align-items-center h-100 nunito">
                      <h1>No Question Made</h1>
                    </div>
                    }
                           
                    </div> 
                </div>
                :""
                }        
                {/* modal end */}
                <Container fluid className='p-top-16'>
                    <Row className="justify-content-center align-items-center">
                        <Col xs='auto' className="text-center">
                        <div>
                                {showAll ? <button onClick={()=>toggleShowAll()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Show Points</button>:<button onClick={()=>toggleShowAll()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Show Question and Answers</button>}
                                <button onClick={()=>startGame()} className="btn grow-small btn-rounded btn-light-purple navbar-btn" >Play</button>
                        </div>
                        </Col>
                    </Row>
                </Container>
                {/* GAME BOARD */}
                <div className='game-body'>

                    <div className={"grid-container grid-container-"+ totalColumns}>

                        {[...Array(totalColumns).keys()].map( columnKey => {
                            let columnID = ["a","b","c","d","e","f"];
                            columnKey = columnKey+1;
                            return <div key={columnKey}>


                                <div className={"grid-category jeopardy-col-"+columnKey} style={{height:60/totalRows+"vh"}} >
                                      <p>{categoryNames[columnKey] ? categoryNames[columnKey] : ""}</p>
                                  </div>

                                {[...Array(totalRows).keys()].map( rowKey => {
                                    rowKey=rowKey+1
                                    return <div key={rowKey} className={"grid-item jeopardy-col-"+columnKey+" a1-modal " +(showAll? 'preview-qa':'')} style={{height:60/totalRows+"vh"}} onClick={(e) => openModal(e,columnID[columnKey-1]+rowKey)} >
                                            {showAll ? 
                                            <div>
                                                <Container fluid>
                                                    <Row className="preview-qa-text" >
                                                        <Col xs="6">
                                                        {questions[columnID[columnKey-1]+rowKey] ? <div>
                                                            <p className="preview-qa-header">Question</p>
                                                            <p>{questions[columnID[columnKey-1]+rowKey].question}</p>
                                                            </div>: <p></p> }
                                                        </Col>
                                                        <Col xs="6">
                                                        {questions[columnID[columnKey-1]+rowKey] ? <div>
                                                            <p className="preview-qa-header">Answer</p>
                                                            <p>{questions[columnID[columnKey-1]+rowKey].answer}</p>
                                                            </div>: <p></p> }
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                            :
                                            <p>{points[rowKey]}</p>}
                                        
                                        </div>
                                }
                                )}
                            </div>

                        }
                        )}
                    </div>
                </div>
                              {/* GAME BOARD END*/}
            </div>
        )
    
}

export default PreviewJeopardy;