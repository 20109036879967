import { Input, Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, Button } from 'reactstrap';
import './style.css';
import {useParams} from 'react-router-dom';
import API from "../../utils/API";
import { useState, useContext,useCallback, useEffect } from "react";
import EditKooblo from "../../components/EditKooblo"
import EditJeopardy from "../editJeopardy/editJeopardy"

const EditSet = () => {
    
    const {id} = useParams();

    const [questionSet, setQuestionSet] = useState([]);
    const [gameType, setGameType] = useState("");
    useEffect(()=>{
        
        API.getSet(id)
            .then(res => { 
                console.log(res);
                setQuestionSet(res.data);
                setGameType(res.data.gametype);
            })
            .catch(err => console.log(err));
    },[]);
    switch (gameType) {
        case 'Jeopardy':
            return (<EditJeopardy data={questionSet}></EditJeopardy>);
        case 'Kooblo':
            case 'Kooblo':
                return (<EditKooblo data={questionSet}></EditKooblo>);     
        default:
          return(<div/>)
      }
  
};

export default EditSet; 