import React, { useState, useContext,useCallback, useEffect, useRef} from 'react';
import { useParams } from "react-router-dom";
import green_check from '../../assets/images/green_check.png'
import red_cross from '../../assets/images/red_cross.png'

import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import API from '../../utils/API';
import './style.css';
import treasureChest from './assets/images/treasure_chest.png';
import bombImage from './assets/images/bomb.png';
import shipWreckImage from './assets/images/shipwreck.png';
import goldSteal from './assets/images/gold_steal.png';
import goldXL from './assets/images/gold_XL.png';
import goldL from './assets/images/gold_L.png';
import goldM from './assets/images/gold_M.png';
import goldS from './assets/images/gold_S.png';
import PlayerCard from "../../components/playerCard";
import BigCoinSoundFile from './assets/sounds/big_coin_sound.mp3';
import SmallCoinSoundFile from './assets/sounds/small_coin_sound.mp3';
import BombSoundFile from './assets/sounds/bomb_sound.mp3';
import ShipWreckSoundFile from './assets/sounds/shipwreck_sound.mp3'; 
import TreasureChestSoundFile from './assets/sounds/treasure_chest_sound.mp3'; 
import PirateArrSoundFile from './assets/sounds/pirate_arr_sound.mp3'; 
import pointSound from '../jeopardy/assets/sounds/plus-points.mp3';

// import pirateIslandBackground from './assets/images/pirate_island.jpg'
// const rewards = [
//     { type: "Gold Coin", value: 100, image:goldS , info:"You found 100 gold!"}, 
//     { type: "Gold Coin", value: 300, image:goldM , info:"You found 300 gold!"}, 
//     { type: "Gold Coin", value: 500, image:goldL , info:"You found 500 gold!"}, 
//     { type: "Gold Coin", value: 300, image:goldM , info:"You found 300 gold!"},
//     { type: "Gold Coin", value: 100, image:goldS , info:"You found 100 gold!"},
//     { type: "Gold Coin", value: 300, image:goldM , info:"You found 300 gold!"}, 
//     { type: "Gold Coin", value: 300, image:goldM , info:"You found 300 gold!"}, 
//     { type: "Gold Coin", value: 300, image:goldM , info:"You found 300 gold!"},
//     { type: "Gold Coin", value: 500, image:goldL , info:"You found 500 gold!"}, 
//     { type: "Gold Coin", value: 1000, image:goldXL , info:"You found 1000 gold!"},
//     { type: "Bomb", value: -300 , image:bombImage , info:"Your team has been hurt by a bomb. Lose 300 gold."}, 
//     { type: "Bomb", value: -300, image:bombImage , info:"Your team has been hurt by a bomb. Lose 300 gold."}, 
//     { type: "Bomb", value: -300, image:bombImage , info:"Your team has been hurt by a bomb. Lose 300 gold."},
//     { type: "Steal Enemy's Gold", value: 100, image:goldSteal , info:"You're a mighty pirate! Take 100 gold from each of your enemies!" }, 
//     { type: "Steal Enemy's Gold", value: 100, image:goldSteal , info:"You're a mighty pirate! Take 100 gold from each of your enemies!" },
//     { type: "Ship Wreck", value: -300 , image:shipWreckImage, info:"Your ship has been damaged. Lose 300 gold."  }
//   ];
  const rewards = [
    { type: "Gold Coin", value: 100, image:goldS , info:"You found 100 gold!"}, 
    { type: "Gold Coin", value: 300, image:goldM , info:"You found 300 gold!"}, 
    { type: "Gold Coin", value: 500, image:goldL , info:"You found 500 gold!"}, 
    { type: "Gold Coin", value: 1000, image:goldXL , info:"You found 1000 gold!"},
    { type: "Bomb", value: -300 , image:bombImage , info:"Your team has been hurt by a bomb. Lose 300 gold."}, 
    { type: "Steal Enemy's Gold", value: 100, image:goldSteal , info:"You're a mighty pirate! Take 100 gold from each of your enemies!" },
    { type: "Ship Wreck", value: -300 , image:shipWreckImage, info:"Your ship has been damaged. Lose 300 gold."  }
  ];


function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
}
const TreasureHunt =(props) => {
    const [teams, setTeams] = useState({});
    const [boardSize,setBoardSize] =useState(16);
    const [questionSet,setQuestionSet] = useState({});
    const [questions,setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [modal, setModal] = useState(false);
    const [questionsPool, setQuestionsPool] = useState([...questions]);

    const [openedChests, setOpenedChests] = useState(Array(boardSize).fill(false));
    const [assignedRewards, setAssignedRewards] = useState([]);

    const [gameEnded, setGameEnded] = useState(false);
    const [currentTurn, setCurrentTurn] = useState();
    const toggleModal = () => setModal(!modal);
    const [pointsChange, setPointsChange] = useState(null);

    useEffect(()=>{
      setBoardSize(props.boardSize);
      assignRewards(props.boardSize);
      if(props.questionSet){
        setTeams(props.teams);

        // setAssignedRewards(shuffleArray([...rewards]));


        setQuestionSet(props.questionSet);
        const shuffledQuestions = shuffleArray(props.questionSet.Questions); // Use slice to avoid mutating the original array
        let cleanedQuestions = []
        for (let i = 0; i < shuffledQuestions.length; i++) {
          if(shuffledQuestions[i].question){
            cleanedQuestions.push(shuffledQuestions[i])
          } 
        }
        setQuestions(cleanedQuestions)
        setQuestionsPool(cleanedQuestions)
        setCurrentTurn(0)
      }
    },[])
////~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~handle rewards //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      const assignRewards = (size) => {
        const goldRewards = rewards.filter(reward => reward.type === "Gold Coin");
        const bombRewards = rewards.filter(reward => reward.type === "Bomb");
        const stealRewards = rewards.filter(reward => reward.type === "Steal Enemy's Gold");
        const shipwreckRewards = rewards.filter(reward => reward.type === "Ship Wreck");


        const numBombs = Math.floor(size * 0.1);
        const numSteals = Math.floor(size * 0.1);
        const numShipwrecks = Math.floor(size * 0.1);
        const numGold = size - numBombs - numSteals - numShipwrecks; // Remaining rewards are gold
    
        const newAssignedRewards = [];

        // Add gold rewards
        for (let i = 0; i < numGold; i++) {
            const randomIndex = Math.floor(Math.random() * goldRewards.length);
            newAssignedRewards.push(goldRewards[randomIndex]);
        }

        // Add bomb rewards
        for (let i = 0; i < numBombs; i++) {
            const randomIndex = Math.floor(Math.random() * bombRewards.length);
            newAssignedRewards.push(bombRewards[randomIndex]);
        }

        // Add steal rewards
        for (let i = 0; i < numSteals; i++) {
            const randomIndex = Math.floor(Math.random() * stealRewards.length);
            newAssignedRewards.push(stealRewards[randomIndex]);
        }

        // Add shipwreck rewards
        for (let i = 0; i < numShipwrecks; i++) {
            const randomIndex = Math.floor(Math.random() * shipwreckRewards.length);
            newAssignedRewards.push(shipwreckRewards[randomIndex]);
        }

        // Shuffle the assigned rewards
        newAssignedRewards.sort(() => Math.random() - 0.5);
        console.log(newAssignedRewards);
        setAssignedRewards(newAssignedRewards);
      };
////~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~handle rewards end~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//full screen handler//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const elementRef = useRef(null);
  const [fullscreen, setFullScreen] = useState(false)
  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      // Enter fullscreen
      if (elementRef.current.requestFullscreen) {
        elementRef.current.requestFullscreen();
      } else if (elementRef.current.mozRequestFullScreen) { /* Firefox */
        elementRef.current.mozRequestFullScreen();
      } else if (elementRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elementRef.current.webkitRequestFullscreen();
      } else if (elementRef.current.msRequestFullscreen) { /* IE/Edge */
        elementRef.current.msRequestFullscreen();
      }
      setFullScreen(true)
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
      setFullScreen(false)

    }
  };


//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~full screen handler end ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE AUDIO TOGGLE START~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            const [isMuted, setIsMuted] = useState(false);
            const [volume, setVolume] = useState(1); // Volume ranges from 0.0 to 1.0   
            const audioRefs = useRef([]);

            const playSound = (soundFile, volume) => {
              const audio = new Audio(soundFile);
              audio.volume = volume; // Set the volume
              audio.play();
              audioRefs.current.push(audio); // Add audio instance to the tracking array
            };  
            const stopAllSounds = () => {
              audioRefs.current.forEach(audio => {
                audio.pause();
                audio.currentTime = 0; // Reset audio to the beginning
              });
              audioRefs.current = []; // Clear the array
            };
            const playPointSound = () => {
              if (!isMuted) {
              playSound(pointSound, volume);
              }
            };

            const playBigCoinSound = () => {
              if (!isMuted) {
              playSound(BigCoinSoundFile, volume);
              }
            };
            
            const playSmallCoinSound = () => {
              if (!isMuted) {
              playSound(SmallCoinSoundFile, volume);
              }
            };
            
            const playBombSound = () => {
              if (!isMuted) {
              playSound(BombSoundFile, volume);
              }
            };
            
            const playShipWreckSound = () => {
              if (!isMuted) {
              playSound(ShipWreckSoundFile, volume);
              }
            };
            
            const playTreasureChestSound = () => {
              if (!isMuted) {
              playSound(TreasureChestSoundFile, volume);
              }
            };
            
            const playPirateArrSound = () => {
              if (!isMuted) {
              playSound(PirateArrSoundFile, volume);
              }
            };
                      
            const changeVolume = (event) => {
                const volumeValue = event.target.value;
                setVolume(volumeValue);
                if (volumeValue == 0) {
                  setIsMuted(true);
                } else {
                  setIsMuted(false);
                }
              };
              const toggleMute = () => {
                setIsMuted(!isMuted);
              };
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE AUDIO TOGGLE END~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Modal Handler~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    const timeoutRef = useRef(null);
    const [modalCss, setModalCss]= useState();
    const [modalExpand, setModalExpand] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [currentChest, setCurrentChest] = useState(null);
    const handleClick = (e,index) => {

      setShowReward(false);
      setCurrentQuestion('');
      setShowContent(false);
      setCurrentChest(index);
      setShowAnswer(false);
      setModalExpand(false);
      setModalCss({top: e.clientY+"px", left: e.clientX+"px", background:'#1f3540'});

      if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setModalExpand(true);
        }, 50);    

      toggleModal();
      setTimeout(() => {
          setShowContent(true);
      }, 100); // Adjust this timeout to match the CSS transition duration
      let questionsPoolTemp = [...questionsPool]
      if (questionsPoolTemp.length > 0) {
        setCurrentQuestion(questionsPool[0]);
        setQuestionsPool(questionsPool.slice(1));
      }              
        
    };
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Modal Handler end~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    useEffect(() => {
      if (questionsPool.length === 0 && questions.length > 0) {
          setQuestionsPool([...questions]);
      } 
    }, [questionsPool, questions]);

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Answer correctness code~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    const onShowAnswer = ()=>{
      setShowAnswer(!showAnswer);
    }
  
    const [showReward, setShowReward] = useState(false);
    const [isShaking, setIsShaking] = useState(true);

    const correctAnswer=()=>{
      const newOpenedChests = [...openedChests];
      newOpenedChests[currentChest] = true;
      setOpenedChests(newOpenedChests);
      setShowReward(true);
      setIsShaking(true);
    }
    const incorrectAnswer=()=>{
      toggleModal();
      if(teams){
        setCurrentTurn((prevIndex) => (prevIndex + 1) % Object.keys(teams).length);
      }
      stopAllSounds();
  }
      useEffect(() => {
      if (showReward && isShaking) {
        playTreasureChestSound();
        const timer = setTimeout(() => {
          setIsShaking(false);
          // setCurrentTurn((prevIndex) => (prevIndex + 1) % Object.keys(teams).length);
          if(assignedRewards[currentChest].type==="Gold Coin"){
            let teamsTemp = {...teams}
            teamsTemp[currentTurn].points =teamsTemp[currentTurn].points+ assignedRewards[currentChest].value;
            let pointsChangeTemp = [];
            pointsChangeTemp.push({team:currentTurn, change:assignedRewards[currentChest].value});
            setPointsChange(pointsChangeTemp);
            if(assignedRewards[currentChest].value <500){
              playSmallCoinSound();
            } else {
              playBigCoinSound();
            }
            setTeams(teamsTemp)
          }else if(assignedRewards[currentChest].type==="Bomb"){
            let teamsTemp = {...teams}
            teamsTemp[currentTurn].points =teamsTemp[currentTurn].points+ assignedRewards[currentChest].value;
            if(teamsTemp[currentTurn].points < 0){
              teamsTemp[currentTurn].points = 0;
            }
            let pointsChangeTemp = [];
            pointsChangeTemp.push({team:currentTurn, change:assignedRewards[currentChest].value});
            setPointsChange(pointsChangeTemp);
            playBombSound();
            setTeams(teamsTemp);

          }else if(assignedRewards[currentChest].type==="Steal Enemy's Gold"){
            let teamsTemp = {...teams}
            let stolenPoints=0;
            let pointsChangeTemp = [];
            Object.keys(teamsTemp).forEach((key) => {
              if (parseInt(key) !== currentTurn) {
                if(teamsTemp[key].points >= assignedRewards[currentChest].value){
                  teamsTemp[key].points -= assignedRewards[currentChest].value; // Subtract 1 point
                  stolenPoints += assignedRewards[currentChest].value;  
                  pointsChangeTemp.push({team:Number(key), change:-assignedRewards[currentChest].value})
                }
              }
            });
            teamsTemp[currentTurn].points =teamsTemp[currentTurn].points+ stolenPoints;
            pointsChangeTemp.push({team:currentTurn, change:stolenPoints});
            setPointsChange(pointsChangeTemp);
            playPirateArrSound();
            setTeams(teamsTemp)

          }else if(assignedRewards[currentChest].type==="Ship Wreck"){
            let teamsTemp = {...teams}
            teamsTemp[currentTurn].points =teamsTemp[currentTurn].points+ assignedRewards[currentChest].value;
            if(teamsTemp[currentTurn].points < 0){
              teamsTemp[currentTurn].points = 0;
            }
            let pointsChangeTemp = [];
            pointsChangeTemp.push({team:currentTurn, change:assignedRewards[currentChest].value});
            setPointsChange(pointsChangeTemp);
            playShipWreckSound();
            setTeams(teamsTemp)

          }
          setTimeout(() => setPointsChange(null), 2000); // Match the CSS animation duration
        }, 3000); // Duration of the shaking animation in milliseconds
  
        return () => clearTimeout(timer);
      }
    }, [showReward, isShaking]);
  

    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Answer correctness code end~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    const onNewGameClick =()=>{
      setGameEnded(false);
    }

    const addPoints=(key)=>{
      let teamsTemp={...teams}
      teamsTemp[key].points = teamsTemp[key].points+100;
      setTeams(teamsTemp)
      playPointSound();  
    }
    const minusPoints=key=>{
      let teamsTemp={...teams}
      if(teamsTemp[key].points>0){
        teamsTemp[key].points = teamsTemp[key].points-100;
        setTeams(teamsTemp)
        playPointSound();  
      }

    }

    return (
        <div ref={elementRef} className="background-blue-95vh treasure-hunt-container">
                <div>

                <div className="controls-container">
                    <p onClick={handleFullscreen} className="full-screen-button z-3">
                        {fullscreen ? <i className="fa-solid fa-minimize"/> : <i className="fa-solid fa-maximize"/>}
                    </p>
                    <p onClick={toggleMute} className="mute-button z-3">
                        {isMuted || volume === 0 ? <i className="fa-solid fa-volume-mute" style={{color:'rgb(69 0 117)'}}/> : <i className="fa-solid fa-volume-high"/>}
                    </p>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={isMuted ? 0 : volume}
                        onChange={changeVolume}
                        style={{ marginLeft: '10px' }}
                        className="volume-slider z-3"
                        aria-label="Volume"
                    />
                </div>
                </div>

                <Container className="ttt-game-container" fluid>
                    <Row className=" titan-one-regular h-100 text-center">
                        <Col xs='12'>
                          <h1 className='ttt-title'>{questionSet.title}</h1>
                        </Col>


                        <Col xs="12 " className='vertical-center'>
                        <Container>
                            <Row noGutters>
                            {[...Array(boardSize).keys()].map( key => {
                            return <Col xs='3'>
                              {openedChests[key]?
                                // <div className='h-100 w-100' >
                                //   <img className='mh-100 mw-100' src={assignedRewards[key].image}>
                                //   </img>
                                // </div>
                                ''
                                :
                                <div className='treasure-chest-container' >

                                  <div className='h-100 w-100' onClick={(e)=>handleClick(e,key)}>
                                  <img className='mh-100 mw-100' src={treasureChest}>
                                  </img>
                                  <span className="chest-number">{key + 1}</span>
                                  </div>
                                </div>

                              }
                            </Col>
                            })}

                            </Row>
                        </Container>
                        </Col>

                        <Col xs='12'>
                        {gameEnded?
                        <button className="btn show-answer-btn" onClick={onNewGameClick}>New Game</button>
                          :
                          ''
                        }
                        </Col>
                    </Row>

                </Container>
                <Container fluid style={{position:'absolute', bottom:'3%'}}> 
                        <Row className="justify-content-center">
                          {Object.keys(teams).map(key => {
                                    return  <Col xs='2' style={{zIndex:'1'}}>

                                      <PlayerCard
                                      name={teams[key].name}
                                      src={teams[key].image}
                                      points={teams[key].points}
                                      onAddPointsClick={e=>addPoints(key)}
                                      onMinusPointsClick={e=>minusPoints(key)}
                                      className={currentTurn==key? "player-card-buzzed":""}
                                      goldIcon = {true}
                                      pointsChange = {pointsChange}
                                      teamKey={Number(key)}
                                      >
                                      </PlayerCard>
                                      </Col>

                                }
                          )}
                        </Row>
                      </Container>

                {modal ?           
                <div className="jeopardy-modal-container" >
                    
                    <div className={"jeopardy-modal text-center " + (modalExpand? "jeopardy-modal-expand":"") }style={modalCss} onClick={e=>e.stopPropagation()} >
                    <div className='modal-button-container-top'>
                    </div>
                    
                    {showReward?
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <img    
                          className={`  preview-image ${isShaking ? 'shake' : ''}`}
                          src={isShaking ? treasureChest : assignedRewards[currentChest].image}
                          ></img>
                          <hr></hr>
                            <h1 className='m-3'>{isShaking ? '':assignedRewards[currentChest].info}</h1>
                          <div className="jeopardy-button-container titan-one-small">

                            {isShaking ?"":<button className="btn show-answer-btn" onClick={()=>incorrectAnswer()}>close</button>}
                          </div>
                        </div>
                    :
                      (showContent?
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                              {showAnswer?
                                <div className="m-3 nunito">
                                  <h1>{currentQuestion.answer}</h1>
                                </div>
                              :
                                <div>
                                  {currentQuestion.image && <img className="preview-image" src={currentQuestion.image} alt="Image description" />}
                                  <div className="m-3 nunito">
                                    <h1>{currentQuestion.question}</h1>
                                  </div>
                                </div>

                              }

                            <div className="jeopardy-button-container titan-one-small">
                              <div className=" ttt-check-container titan-one-small">
                                    <img src={green_check}className='ttt-check' onClick={()=>correctAnswer()}></img>
                                    <img src={red_cross} className='ttt-check' onClick={()=>incorrectAnswer()}></img>
                              </div>

                              {showAnswer?
                                  <button className="btn show-answer-btn" onClick={()=>onShowAnswer()}>Show Question</button>
                                :
                                  <button className="btn show-answer-btn" onClick={()=>onShowAnswer()}>Show Answer</button>
                              }
                              </div>
                        </div>
                        :""
                      )
                    }
                    </div>
                </div>
                :""
                }
        </div>

    );
  
      
}

export default TreasureHunt;
