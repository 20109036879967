import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card,Container, Col, CardText, Row, CardBody, CardImg} from 'reactstrap';
import Kooblo from '../../assets/images/Kooblo.PNG'
import { PreviewKoobloParty, PreviewKooblo } from '../PreviewQuestion';
const PreviewModal = ({ isOpen, toggle, questions,gameType }) => {
  
  const renderQuestions =()=>{
    if (gameType==='Kooblo Party'){
      return (questions.map((question, index) => (
        <PreviewKoobloParty 
          key={index} 
          question={question} // Pass the question data to PreviewKoobloParty
      />)
      ))
    } else if(gameType==='Kooblo'){
      return (questions.map((question, index) => (
        <PreviewKooblo
          key={index} 
          question={question} // Pass the question data to PreviewKoobloParty
      />)
      ))

    }
  }
  return (
    <Modal 
    isOpen={isOpen}
     toggle={toggle}
    centered
    style={{ 
      maxWidth: '70vw', 
      height: '70vh',
    }}

    >
      <ModalBody 
          style={{ 
            maxWidth: '70vw', 
            height: '70vh',
            background:'lightblue',
            overflowY: 'auto', // Allows vertical scrolling if needed
            overflowX: 'hidden' // Hides horizontal overflow
  
          }}
      >
            {questions.length > 0 ? (
              renderQuestions()
              ) : (
              <p>No questions available.</p>
            )}
      </ModalBody>
        <Button color="secondary" onClick={toggle}>Close</Button>
    </Modal>
  );
};

export default PreviewModal;
