import React, { useContext } from 'react';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import { AuthContext } from '../AuthContext/AuthContext';
import './style.css';

const SubscriptionManager = () => {
    const { user, setUser } = useContext(AuthContext);

    const handlePlanSelection = (plan) => {
        // Logic to handle plan selection
        console.log(`Selected plan: ${plan}`);
        // Example of updating the user's subscription plan
        const updatedUser = {
            ...user,
            subscriptionPlan: plan
        };
        setUser(updatedUser);

        // Redirect to dashboard or make an API call to update subscription in the database
    };

    return (
        <Container className="subscription-manager nunito h-100 d-flex align-items-center justify-content-center" fluid>
            <Row className=''>
                <Col xs="12">
                    <h2 className="text-center my-4 titan-one-regular">Choose Your Kooblo Plan</h2>
                </Col>
                {/* Free Plan */}
                <Col md="4">
                    <Card className="text-center p-4 mb-4">
                        <h3 className='titan-one-regular'>Free Plan</h3>
                        <p className='p-0 m-0'><span className='titan-one-regular text-bold'>$0</span>/month</p> 
                        <br></br>
                        <p><i class="fa-solid fa-check text-success"></i> Create up to 10 question sets </p>
                        <p><i class="fa-solid fa-check text-success"></i> Access basic Kooblo games </p>
                        <p><i class="fa-solid fa-xmark text-danger"></i> Access to Kooblo Plus Games!</p>
                        <p><i class="fa-solid fa-xmark text-danger"></i> Access to all future Kooblo updates!</p>

                        <Button color="primary" onClick={() => handlePlanSelection('Free')}>
                            Choose Free Plan
                        </Button>
                    </Card>
                </Col>
                {/* Yearly Plan */}
                <Col md="4">
                    <Card className="text-center p-4 mb-4">
                        <h3 className='titan-one-regular'>Yearly Plan</h3>
                        <p className='p-0 m-0'><span className='titan-one-regular text-bold'>$4.99</span>/month</p> 
                        <small>$59.99 Billed Annually</small>
                        <br></br>
                        <p><i class="fa-solid fa-check text-success"></i> Create unlimited question sets!</p>
                        <p><i class="fa-solid fa-check text-success"></i>Access basic Kooblo games </p>
                        <p><i class="fa-solid fa-check text-success"></i> Access to Kooblo Plus Games!</p>
                        <p><i class="fa-solid fa-check text-success"></i> Access to all future Kooblo updates!</p>
                        <Button color="primary" onClick={() => handlePlanSelection('Yearly')}>
                            Choose Yearly Plan
                        </Button>
                    </Card>
                </Col>

                {/* Monthly Plan */}
                <Col md="4">
                    <Card className="text-center p-4 mb-4">
                        <h3 className='titan-one-regular'>Monthly Plan</h3>
                        <p className='p-0 m-0'><span className='titan-one-regular text-bold'>$6.99</span>/month</p> 
                        <small>Billed Monthly</small>
                        <br></br>
                        <p><i class="fa-solid fa-check text-success"></i> Create unlimited question sets!</p>
                        <p><i class="fa-solid fa-check text-success"></i> Access basic Kooblo games </p>
                        <p><i class="fa-solid fa-check text-success"></i> Access to Kooblo Plus Games!</p>
                        <p><i class="fa-solid fa-check text-success"></i> Access to all future Kooblo updates!</p>
                        <Button color="primary" onClick={() => handlePlanSelection('Monthly')}>
                            Choose Monthly Plan
                        </Button>
                    </Card>
                </Col>

            </Row>
        </Container>
    );
};

export default SubscriptionManager;
