import { Input, Container, Row, Col, InputGroup } from 'reactstrap';
import './style.css';
import API from "../../utils/API";
import { useState, useEffect } from "react";
import GameCard from '../GameCard';
import { useNavigate } from 'react-router-dom';
import PreviewModal from '../PreviewModal';
const AllGames = () => {
    const [searchText, setSearchText] = useState('');
    const [questionSets, setQuestionSets] = useState([]);
    const [jeopardyChecked, setJeopardyChecked] = useState(false);
    const [koobloChecked, setKoobloChecked] = useState(false);
    const [koobloPartyChecked, setKoobloPartyChecked] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit] = useState(20); // Number of items per page
    const [pageData, setPageData] = useState({});
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectedGameType, setSelectedGameType] = useState([])
    const togglePreviewModal = () => setIsPreviewModalOpen(!isPreviewModalOpen);

    const navigate = useNavigate();

    const onJeopardyCheckClick = (e) => {
        setJeopardyChecked(!jeopardyChecked);
    };

    const onKoobloCheckClick = (e) => {
        setKoobloChecked(!koobloChecked);
    };

    const onKoobloPartyCheckClick = (e) => {
        setKoobloPartyChecked(!koobloPartyChecked);
    };
    const onSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleSearch = () => {
        const cacheKey = `${searchText}-${jeopardyChecked}-${koobloChecked}-${koobloPartyChecked}-${currentPage}`;
        const offset = (currentPage - 1) * limit;
        console.log( `${searchText}-${jeopardyChecked}-${koobloChecked}-${koobloPartyChecked}-${currentPage}`)

        // Check if the data is already cached
        if (pageData[cacheKey]) {
            setQuestionSets(pageData[cacheKey]);
            return;
        }

        // If data is not cached, make an API call
        API.getAllSets({
            limit: limit, 
            offset: offset,
            title: searchText,
            jeopardyChecked: jeopardyChecked,
            koobloChecked: koobloChecked,
            koobloPartyChecked: koobloPartyChecked
        })
        .then(res => {
            console.log('getting data after api')
            console.log(res.data)
            setQuestionSets(res.data.data);
    
            // Cache the data with the current filters and page as the key
            setPageData(prev => ({
                ...prev,
                [cacheKey]: res.data.data,
            }));
    
            setTotalPages(res.data.totalPages);
        })
        .catch(err => console.log(err));
    };
    
    // Function to clear the cache when new search filters are applied
    const resetCache = () => {
        setPageData({});
        setCurrentPage(1);
    };
    
    // Call resetCache whenever the search criteria (filters) change
    useEffect(() => {
        resetCache();
    }, [searchText, jeopardyChecked, koobloChecked, koobloPartyChecked]);
    
    const onSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            // Implement your search logic here
            handleSearch();
        }
    };

  
    const onViewClick = (gametype,questions, id) =>{
        switch (gametype) {
            case 'Jeopardy':
                window.open('/jeopardy/preview/' + id, '_blank');
                break;
            default:
                setSelectedGameType(gametype)
                setSelectedQuestions(questions); // Assuming the response contains questions
                setIsPreviewModalOpen(true);
                break;
            }
    }

    const onPlayClick = (gametype,id) =>{
        switch (gametype) {
            case 'Jeopardy':
                navigate('/play/jeopardy/' + id);
                break;
            case 'Kooblo':
              navigate('/kooblo/play/' + id);
              break;
            case 'Kooblo Party':
                navigate('/koobloparty/play/' + id);
                break;  
          }
    }
    useEffect(()=>{
        handleSearch()
    },[currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return(
            <Container fluid>
            <Row className='m-2 align-items-center justify-content-center' style={{borderRadius:'5px'}}>
            <Col lg={12} md={12}  className='d-flex align-items-center justify-content-center'>
                <InputGroup className='search-input-group' >
                    <Input
                        type='text'
                        placeholder='Search'
                        value={searchText}
                        onChange={onSearchChange}
                        onKeyDown={onSearchKeyDown}
                        className='search-input'
                        style={{
                            borderRadius: '25px',
                        }}
                    />
                    <i 
                        className="fas fa-search search-icon" 
                        onClick={handleSearch}
                    ></i>
                </InputGroup>
            </Col>
            <Col md='auto' >
            <Container>
                <Row className='d-flex titan-one-small align-items-center justify-content-center vertical-center m-3'>
                    <Col xs='auto'>
                    <p className="mb-0" > <i className={`fas fa-check search-check-button ${jeopardyChecked ? 'is-checked' : ''}`} onClick={onJeopardyCheckClick}></i>Jeopardy</p>
                    </Col>
                    <Col xs='auto'>
                    <p className="mb-0"><i className={`fas fa-check search-check-button ${koobloChecked ? 'is-checked' : ''}`} onClick={onKoobloCheckClick}></i>Kooblo </p>
                    </Col>
                    <Col xs='auto'>
                    <p className="mb-0"><i className={`fas fa-check search-check-button ${koobloPartyChecked ? 'is-checked' : ''}`} onClick={onKoobloPartyCheckClick}></i>Kooblo Party </p>
                    </Col>
                </Row>
            </Container>
            </Col>

            </Row>
            <Row>
                {questionSets && questionSets.map(games =>
                    <Col xl="3" lg='6'key={games.id} className='mb-5'>
                        <GameCard 
                        id={games.id} 
                        gametype={games.gametype} 
                        photos={games.image}
                        name={games.title} 
                        about={games.description} 
                        questionCount={games.Questions.length}
                        onPlayClick={e=> onPlayClick(games.gametype, games.id)} 
                        onViewClick={e=> onViewClick(games.gametype, games.Questions, games.id)}
                        />
                    </Col>
                )}       
            </Row>
            <div className="pagination-container " >
                {currentPage > 1 && (
                    <button  style={{borderRadius:'25px'}} className='btn nav-btn-light-purple navbar-btn' onClick={() => handlePageChange(currentPage - 1)}>
                       <i className="fa-solid fa-arrow-left"></i> Previous
                    </button>
                )}
                {totalPages > 1 && (
                    Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            disabled={currentPage === index + 1}
                            className="pagination-button titan-one-small"
                        >
                            {index + 1}
                        </button>
                    ))
                )}
                {currentPage < totalPages && (
                    <button style={{borderRadius:'25px'}} className='btn nav-btn-light-purple navbar-btn' onClick={() => handlePageChange(currentPage + 1)}>
                        Next <i className="fa-solid fa-arrow-right"></i>
                    </button>
                )}
            </div>
                        
            <PreviewModal 
                isOpen={isPreviewModalOpen} 
                toggle={togglePreviewModal} 
                questions={selectedQuestions} 
                gameType={selectedGameType}
            />

        </Container>
    )
};

export default AllGames; 