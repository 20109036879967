import React from "react";
import './style.css'
export function Footer(){
    return(        
        <footer class="footer p-5 footer nunito" > 
        <div class="container"> 
            <div class="row"> 
                <div class="col-md-3"> 
                    <h2>Kooblo.io</h2> 
                </div> 
                <div class="col-md-3"> 
                    <h5>About Us</h5> 
                    <p> 
                    Kooblo.io is an ed-tech platform where teachers can create custom question sets and turn them into interactive games, making learning more engaging and enjoyable for students.
                    </p> 
                </div> 
                <div class="col-md-3"> 
                    <h5>Contact Us</h5> 
                    <ul class="list-unstyled"> 
                        <li>Email: support@kooblo.io</li> 
                        {/* <li>Phone:</li>  */}
                        <li>Address: 123 Innovation Road, Xinyi District, Taipei 110, Taiwan</li> 
                    </ul> 
                </div> 
                <div class="col-md-3"> 
                    <h5>Follow Us</h5> 
                    <ul class="list-inline footer-links" > 
                        <li class="list-inline-item"> 
                          <a href="#"> 
                                <i class="fab fa-facebook" style={{color:"#9500FE"}}></i> 
                          </a> 
                          </li> 
                        <li class="list-inline-item"> 
                          <a href="#"> 
                                <i class="fab fa-twitter" style={{color:"#9500FE"}}></i> 
                          </a> 
                        </li> 
                        <li class="list-inline-item"> 
                          <a href="#"> 
                                <i class="fab fa-instagram" style={{color:"#9500FE"}}></i> 
                          </a> 
                        </li> 
                        <li class="list-inline-item"> 
                          <a href="#"> 
                                <i class="fab fa-linkedin" style={{color:"#9500FE"}}></i> 
                          </a> 
                        </li> 
                    </ul> 
                </div> 
            </div> 
            <hr/> 
            <div class="row"> 
                <div class="col-md-6"> 
                    <p>© 2024 Kooblo.io. All rights reserved.
                    </p> 
                </div> 
                <div class="col-md-6 text-end"> 
                    <ul class="list-inline footer-links"> 
                        <li class="list-inline-item"> 
                            <a href="#" class="text-white"> 
                                Privacy Policy 
                            </a> 
                        </li> 
                        <li class="list-inline-item"> 
                            <a href="#" class="text-white"> 
                                Terms of Service 
                            </a> 
                        </li> 
                        <li class="list-inline-item"> 
                            <a href="#" class="text-white"> 
                                Careers
                            </a> 
                        </li> 
                    </ul> 
                </div> 
            </div> 
        </div> 
    </footer> 
);
}