import { Label, Input, Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, Button } from 'reactstrap';
import './style.css';
import {useParams} from 'react-router-dom';
import API from "../../utils/API";
import { useState, useContext,useCallback, useEffect } from "react";
import ClassCard from '../ClassCard';

const ViewStudents = (props) => {
    const [questionSets, setQuestionSets] = useState([]);
    const [classes, setClasses] = useState([]);
    const [studentNamesText, setStudentNamesText] = useState("");
    const [studentTextBox, setStudentTextBox] = useState(false);
    const [remStuBtn, setRemStuBtn] = useState(false);
    const students = props.classdata.Students;
    const columnsPerRow = 4; // Since sm="3" means each row can have 4 columns
    const totalStudents = students.length;
    const extraColumnsNeeded = (columnsPerRow - (totalStudents % columnsPerRow)) % columnsPerRow;


    const addStudents = (e)=>{
        console.log(props.classdata.Students)
        const studentNamesSplit = studentNamesText.trim().split('\n').map(name => name.trim()).filter(name => name.length > 0);
        let studentNames = [];

        for(let i = 0; i < studentNamesSplit.length; i++){
            let studentName={};
            studentName.name = studentNamesSplit[i];
            studentName.ClassId = props.classID;
            studentNames.push(studentName);
        }
        if (studentNamesText.length === 0) {
            alert('Please enter at least one student name.');
            return;
        }
        API.saveStudents(studentNames).then(res=>{
            console.log(res)
            props.studentChangesListener();
            setStudentTextBox(!studentTextBox);
        }).catch(err=>{
            console.log(err)
        })
    }
    const studentNamesTextChange= e =>{
        setStudentNamesText(e.target.value);
    }
    const toggleStudentTextBox = (e) =>{
        setStudentTextBox(!studentTextBox);
        setRemStuBtn(false);
    };
    const toggleRemStuBtn = (e) =>{
        setRemStuBtn(!remStuBtn);
        setStudentTextBox(false);

    };
    const deleteStudent = (e,id) =>{
        let data = {"id":id};
        API.deleteStudent(data).then(res=>{
            props.studentChangesListener();
        }).catch(err=>{
            console.log(err)
        })
    };

    return(
        <Container>
            <Row className="h-100">
                <Col sm="12" className="align-self-center">
                    <div className="">
                        <Card className=" students-container">
                        <div className='h-100'>
                            <h3 className="text-center titan-one-regular p-0 m-0 mb-3">{props.classdata.name}</h3>
                            <Container>
                                <Row className='mb-3'>
                                    <Col sm="12" className='text-center'>
                                    <button onClick={toggleStudentTextBox} className="btn btn-rounded nav-btn-light-blue navbar-btn">Add Students</button>
                                    <button onClick={ toggleRemStuBtn} className="btn btn-rounded nav-btn-light-blue navbar-btn">Remove Students</button>
                                    </Col>
                                </Row>
                                {studentTextBox?                          
                                    <Row className='mb-3'>
                                        <Col lg="3"/>
                                        <Col lg="6" className='text-center'>
                                            <Container>
                                            <Row className='mb-3'>
                                                <Col sm="6" >
                                                    <Label className="titan-one-regular p-0 m-0" for="urlText">Enter student names</Label>
                                                    <p className='text-muted'>Enter a new name on each row</p> 
                                                </Col>
                                                <Col sm="6" className='align-self-center'>
                                                <button onClick={ addStudents} className="btn btn-rounded nav-btn-light-blue navbar-btn">Save</button>
                                                <button onClick={toggleStudentTextBox} className="btn btn-rounded nav-btn-light-red navbar-btn">Cancel</button>
                                                </Col>
                                            </Row>
                                            </Container>
                                            <Input id="studentNames" type="textarea" placeholder={studentNamesText} rows="10" className="class-card-title m-0 p-0 h-auto" onChange={e=>studentNamesTextChange(e)}/>
                                        </Col>
                                    </Row>
                                    :
                                    ""   
                                }
                                <Row className='d-flex text-center mb-3'>
                                    {props.classdata.Students.map( i=>{
                                    return <Col md='6' lg="3" className='col-center'>
                                        <div className='student-name-plate '>
                                        {remStuBtn && <i className="fa-solid fa-trash-can icon-red" onClick={e => deleteStudent(e, i.id)}></i>}
                                        <span className="student-name">{i.name}</span>
                                        </div>
                                    </Col>
                                    }
                                    )}
                                    {[...Array(extraColumnsNeeded)].map((_, index) => (
                                        <Col md='6' lg="3" key={`extra-${index}`} className='col-center'>
                                            {/* Empty column to fill the row */}
                                        </Col>
                                    ))}

                                </Row>
                            </Container>
                            {/* <Label className="titan-one-regular p-0 m-0" for="urlText">Class Name:</Label>
                            <Input id="studentNames" type="textarea" placeholder={studentNamesText} rows="10" className="class-card-title m-0 p-0 h-auto" onChange={e=>studentNamesTextChange(e)}/> */}
                        </div>
                        </Card>
                    </div>
                </Col>

            </Row>
        </Container>
    )
};

export default ViewStudents; 