import Phaser from 'phaser';
// Images
import ballImg from './assets/images/ball.png';
import hoopImg from './assets/images/hoop.png';
import sideRimImg from './assets/images/side_rim.png';
import frontRimImg from './assets/images/front_rim.png';
import win0Img from './assets/images/win0.png';
import win1Img from './assets/images/win1.png';
import win2Img from './assets/images/win2.png';
import win3Img from './assets/images/win3.png';
import win4Img from './assets/images/win4.png';
import lose0Img from './assets/images/lose0.png';
import lose1Img from './assets/images/lose1.png';
import lose2Img from './assets/images/lose2.png';
import lose3Img from './assets/images/lose3.png';
import lose4Img from './assets/images/lose4.png';
import bgImg from './assets/images/basketball-bg.png'; // Import the background image

// Audio
import scoreAudio from './assets/audio/score.wav';
import backboardAudio from './assets/audio/backboard.wav';
import whooshAudio from './assets/audio/whoosh.wav';
import failAudio from './assets/audio/fail.wav';
import spawnAudio from './assets/audio/spawn.wav';

class BasketballGameScene extends Phaser.Scene {
  
  constructor() {
    super({ key: 'BasketballGameScene' });

    this.ball = null;
    this.hoop = null;
    this.leftRim = null;
    this.rightRim = null;
    this.currentScore = 0;
    this.currentScoreText = null;
    this.highScoreText = null;
    this.currentBestText = null;
    this.emoji = null;
    this.emojiName = '';
    this.cursors = null;

    this.scoreSound = null;
    this.backboard = null;
    this.whoosh = null;
    this.fail = null;
    this.spawn = null;

    this.isDown = false;
    this.startLocation = null;
    this.endLocation = null;
  }

  preload() {
    // Preload images
    this.load.image('ball', ballImg);
    this.load.image('hoop', hoopImg);
    this.load.image('side_rim', sideRimImg);
    this.load.image('front_rim', frontRimImg);
    this.load.image('win0', win0Img);
    this.load.image('win1', win1Img);
    this.load.image('win2', win2Img);
    this.load.image('win3', win3Img);
    this.load.image('win4', win4Img);
    this.load.image('lose0', lose0Img);
    this.load.image('lose1', lose1Img);
    this.load.image('lose2', lose2Img);
    this.load.image('lose3', lose3Img);
    this.load.image('lose4', lose4Img);
    this.load.image('background', bgImg); // Preload background image
  
    // Preload audio
    this.load.audio('score', scoreAudio);
    this.load.audio('backboard', backboardAudio);
    this.load.audio('whoosh', whooshAudio);
    this.load.audio('fail', failAudio);
    this.load.audio('spawn', spawnAudio);
  }

  create() {  
    // Draw debug information
  
    // Add background image
    const background = this.add.image(0, 0, 'background');
    background.setOrigin(0, 0); // Set origin to top-left
    background.setDisplaySize(this.cameras.main.width, this.cameras.main.height); // Scale background to match camera size
  
    // Create text objects for score and best score
    this.currentScoreText = this.add.text(185, 0, this.currentScore,  {
      fontFamily: 'Titan One',
      fontSize: '40px',
      fill: '#fff',
      stroke: '#000',
      strokeThickness: 6
    });  

    this.hoop = this.add.sprite(200, 134, 'hoop');
    this.leftRim = this.add.sprite(150, 184, 'side_rim');
    this.rightRim = this.add.sprite(250, 184, 'side_rim');
  
    this.physics.add.existing(this.leftRim);
    this.physics.add.existing(this.rightRim);
    this.leftRim.body.setCircle(2.5);
    this.leftRim.body.setImmovable(true);
    this.rightRim.body.setCircle(2.5);
    this.rightRim.body.setImmovable(true);
  // Disable gravity on the rims to prevent them from falling
  this.leftRim.body.allowGravity = false;
  this.rightRim.body.allowGravity = false;

    this.scoreSound = this.sound.add('score');
    this.backboard = this.sound.add('backboard', { volume: 0.5 });
    this.whoosh = this.sound.add('whoosh');
    this.fail = this.sound.add('fail', { volume: 0.1 });
    this.spawn = this.sound.add('spawn');
  
    this.input.on('pointerdown', this.handlePointerDown, this);
    this.input.on('pointerup', this.handlePointerUp, this);
    console.log(this.game)
    this.createBall();
  }
   
  update() {
    if (this.ball) {
      // Calculate scale factor based on y-position
      // let scaleFactor = 1 - (this.ball.y / this.game.config.height); // Adjust scaling calculation as needed
      // scaleFactor = Phaser.Math.Clamp(scaleFactor, 0.5, 1); // Limit the scale between 0.5 and 1
  
      // // Apply scale to the ball
      // this.ball.setScale(scaleFactor);
      if (this.ball.body.velocity.y > 0) {
        this.frontRim = this.add.sprite(200, 184, 'front_rim');
        this.physics.add.collider(this.ball, this.leftRim, this.hitRim, null, this);
        this.physics.add.collider(this.ball, this.rightRim, this.hitRim, null, this);
      }
  
      if (this.ball && this.ball.body.velocity.y > 0 && this.ball.body.y > 188 && !this.ball.isBelowHoop) {
        this.ball.isBelowHoop = true;
        this.ball.body.setCollideWorldBounds(false);
        let rand = Math.floor(Math.random() * 5);
        if (this.ball.body.x > 150 && this.ball.body.x < 250) {
          this.emojiName = 'win' + rand;
          this.currentScore += 1;
          this.game.reactSetScore(this.currentScore)
          this.currentScoreText.text = this.currentScore;
          this.scoreSound.play();
        } else {
          this.emojiName = 'lose' + rand;
          this.fail.play();
        }
        this.emoji = this.add.sprite(200, 100, this.emojiName);
        this.emoji.setScale(0.25);
        this.tweens.add({
          targets: this.emoji,
          y: 150,
          ease: 'Elastic',
          duration: 500,
          yoyo: false,
          onComplete: () => {
            this.tweens.add({
              targets: this.emoji,
              alpha: 0,
              duration: 300,
              ease: 'Linear',
              onComplete: () => {
                this.emoji.destroy();
              }
            });
          }
        });
      }
  
      if (this.ball && this.ball.body.y > 1200) {
        this.physics.world.gravity.y = 0;
        this.ball.destroy();
        this.game.pause();
        console.log(this.game)
        console.log(this.game)

        this.game.openQuestion();
        this.createBall();

      }
    }
  }
  
  hitRim() {
    this.backboard.play();
  }

  createBall() {
    let xpos = this.currentScore === 0 ? 200 : 60 + Math.random() * 280;
    this.spawn.play();
    this.ball = this.add.sprite(xpos, 547, 'ball');
    this.physics.add.existing(this.ball);
    this.ball.body.setCircle(60);
    this.ball.launched = false;
    this.ball.isBelowHoop = false;

    // Reset ball scale to normal size
    this.ball.setScale(1);
    // Don't apply gravity yet
    this.physics.world.gravity.y = 0;
    
  }
  
  handlePointerDown(pointer) {
    // Check if the pointer is within the bounds of the ball
    if (this.ball && Phaser.Geom.Rectangle.ContainsPoint(this.ball.getBounds(), pointer)) {
      this.startLocation = [pointer.x, pointer.y];
      this.isDown = true;
      console.log('pointer down')

      this.locationInterval = setInterval(() => {
        this.startLocation = [pointer.x, pointer.y];
      }, 200);
    }
  }
      
  handlePointerUp(pointer) {
    if (this.isDown) {
      clearInterval(this.locationInterval);
      this.isDown = false;
      this.endLocation = [pointer.x, pointer.y];

      if (this.endLocation[1] < this.startLocation[1]) {
        let slope = [this.endLocation[0] - this.startLocation[0], this.endLocation[1] - this.startLocation[1]];
        let xTraj = -2300 * slope[0] / slope[1];
        this.launch(xTraj);
        console.log('pointer up')

      }
    }
  }
      
  launch(xTraj) {
    if (!this.ball.launched) {
      this.ball.body.setCircle(36);
      this.ball.body.setBounce(0.7);
      this.ball.launched = true;
      this.physics.world.gravity.y = 3000;
      this.ball.body.setVelocity(xTraj, -1750);
      this.ball.body.setAngularVelocity(xTraj / 3);
      this.whoosh.play();
      
      // Add scaling down effect
      this.tweens.add({
        targets: this.ball,
        scaleX: 0.5,
        scaleY: 0.5,
        ease: 'Linear',
        duration: 500
      });
    }

  }
    
}

export default BasketballGameScene;
