import { Label, Input, Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, Button } from 'reactstrap';
import './style.css';
import {useParams} from 'react-router-dom';
import API from "../../utils/API";
import { useState, useContext,useCallback, useEffect } from "react";
import ClassCard from '../ClassCard';
import  ViewStudents from '../ViewStudents';

const Class = () => {
    // class data from database
    const [classes, setClasses] = useState({});
    //set temporary classes data to be edited
    const [editedClasses, setEditedClasses] = useState({});

    //name used to add class
    const [className, setClassName] = useState("");

    //set class ID when viewing class component
    const [currentClass, setCurrentClass] = useState("");

    //toggled to change view 
    const [addClass, setAddClass] = useState(false);
    const [classNameEditor, setClassNameEditor] = useState({});
    const [viewClassComponent, setViewClassComponent] = useState(false);
    const [deleteClassView, setDeleteClassView] = useState({});
    const [studentChanges, setStudentChanges] = useState(false);
    useEffect(()=>{ 
        API.getClasses()
            .then(res => { 
                let editToggle = {};
                let deleteClassViewToggle = {};
                let classesTemp = {};
                for(let i = 0; i < res.data.length; i++){
                    editToggle[res.data[i].id] = false;
                    classesTemp[res.data[i].id]=res.data[i];
                    deleteClassViewToggle[res.data[i].id]=false;
                };
                setDeleteClassView(deleteClassViewToggle);
                setClassNameEditor(editToggle);
                setClasses(classesTemp);
                setEditedClasses(classesTemp);
                console.log(classesTemp)
            })
            .catch(err => console.log(err));
    },[studentChanges]);
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ADD NEW CLASS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    const onClassNameChange =(e)=>{
        setClassName(e.target.value);
    }
    const submitClass = (e)=>{
        let newClass={};
        newClass.name=className;
        API.addClass(newClass).then(res => { 
            console.log(res);
        })
        .catch(err => console.log(err));;
    }
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~EDIT CLASS NAME~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    const onEditClassNameChange =(e,i)=>{
        let editedClassesTemp = {...editedClasses};
        editedClassesTemp[i].name=e.target.value;
        setEditedClasses(editedClassesTemp);
    }
    const submitEditedClass = (e,i)=>{
        let newClass={};
        newClass=editedClasses[i];
        API.addClass(newClass).then(res => { 
            console.log(res);
            let classNameEditorTemp  = {...classNameEditor};
            classNameEditorTemp[i]=!classNameEditor[i];
            setClassNameEditor(classNameEditorTemp);    
        })
        .catch(err => console.log(err));
    }
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Handle student changes~~~~~~~~~~~~~~~~~~~~~~~~~
    const onNewStudentsAdded = e =>{
        setStudentChanges(!studentChanges);
    }
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~button activity~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    const toggleAddClass = (e) =>{
        setAddClass(!addClass);
    }

    const viewClassToggle = (e,id) =>{
        console.log("parent component" + id)
        setViewClassComponent(true);
        setCurrentClass(id);
    };

    const toggleClassNameEditor = (e,i) =>{
        let classNameEditorTemp  = {...classNameEditor};
        classNameEditorTemp[i]=!classNameEditor[i];
        setClassNameEditor(classNameEditorTemp);
        setEditedClasses({...classes});
    };

    const deleteClassToggle = (e,i) =>{
        console.log(deleteClassView)
        let deleteClassViewTemp  = {...deleteClassView};
        deleteClassViewTemp[i]=!deleteClassView[i];
        setDeleteClassView(deleteClassViewTemp);
    };

    return(
        <Container className='h-100' fluid>
            {viewClassComponent? 
            <ViewStudents 
                onBackClick={e=>( setViewClassComponent(false))}
                classID={currentClass}
                studentChangesListener={e=>(onNewStudentsAdded())}
                classdata={classes[currentClass]}
            />:
    
            <Row>
                {addClass ? 
                <Col xxl="2" xl="3" lg="4" >
                    <div>
                        <Card className="class-card-container align-content-center">
                        <div className='h-100'>
                            <Label className="titan-one-regular p-0 m-0" for="urlText">Class Name:</Label>
                            <Input id="className" className="class-card-title m-0 p-0 h-auto" onChange={e=>(onClassNameChange(e))}/>
                        </div>
                            <div className="class-link-group nunito">
                                <p className="class-card-custom-link" onClick={e=>submitClass(e)}>Add</p>
                                <p className="class-card-custom-link class-card-custom-link-delete" onClick={e=>toggleAddClass(e)}>Cancel</p>
                            </div>
                        </Card>
                    </div>
                </Col>
                :                
                <Col xxl="2" xl="3" lg="4" >
                    <div>
                        <Card className="class-card-container  add-class-card text-center align-content-center" onClick={e=>toggleAddClass(e)}>
                            <div className="class-card-title nunito"><i class="fa-solid fa-plus"></i> Add Class</div>
                        </Card>
                    </div>
                </Col>
                }

                {Object.keys(classes).map( i =>
                    
                    <Col xxl="2" xl="3" lg="4"key={classes[i].id}>
                        {classNameEditor[i]? 
                            <div>
                                <Card className="class-card-container align-content-center">
                                <div className='h-100'>
                                    <Label className="titan-one-regular p-0 m-0" for="className">Class Name:</Label>
                                    <Input id="className" value={editedClasses[i].name} className="class-card-title m-0 p-0 h-auto" onChange={e=>(onEditClassNameChange(e,i))}/>
                                </div>
                                    <div className="class-link-group nunito">
                                        <p className="class-card-custom-link" onClick={e=>submitEditedClass(e,i)}>Save</p>
                                        <p className="class-card-custom-link class-card-custom-link-delete" onClick={e=>toggleClassNameEditor(e, i)}>Cancel</p>
                                    </div>
                                </Card>
                            </div>
                        :   
                               deleteClassView[i] ?
                               <div>
                                    <Card className="class-card-container align-content-center">
                                    <div className='h-100 align-content-center text-center'>
                                        <i class="fa-solid fa-trash-can"></i>
                                        <p className="titan-one-regular p-0 m-0" >Are you sure?</p>
                                    </div>
                                        <div className="class-link-group nunito">
                                            <p className="class-card-custom-link class-card-custom-link-delete" onClick={e=>submitEditedClass(e,i)}>Yes</p>
                                            <p className="class-card-custom-link " onClick={e=>deleteClassToggle(e, i)}>No</p>
                                        </div>
                                    </Card>
                                </div>
                                :
                                <ClassCard id={classes[i].id} studentCount={classes[i].Students.length} name={classes[i].name} onViewClick={e=>viewClassToggle(e,classes[i].id)} onEditClick={e=>toggleClassNameEditor(e, i)} onDeleteClick={e=>deleteClassToggle(e,i)}/>
        
                            
                        }
                    </Col>
                )}       
            </Row>
             }
        </Container>
    )
};

export default Class; 