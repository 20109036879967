import "./style.css";
import {  Card, CardTitle, Container, Row, Col } from 'reactstrap';
import React, { useState,useEffect } from 'react';
const PlayerCard = (props) => {
    const [thisPointsChange, setThisPointsChange] = useState();
    useEffect(()=>{
        setThisPointsChange(null);
        if(props.pointsChange){
            for(let i =0; i<props.pointsChange.length; i++){
                if(props.pointsChange[i].team === props.teamKey){
                    setThisPointsChange(props.pointsChange[i]);
                }
            }    
        }
    },[props.pointsChange])
  return (
    <div>
                {props.questionSelected?
                    <div className="player-correct-container nunito text-center">
                        <p className="player-correct text-center player-check" onClick={props.onCorrectClick} ><i class="fa-solid fa-check"></i></p>
                        <p className="player-correct text-center player-xmark" onClick={props.onIncorrectClick}><i class="fa-solid fa-xmark"></i></p>
                    </div>
                :
                ''
                }
                <Card className={"player-card-container m-0 text-center nunito "+ props.className}  >
                    <Container fluid>
                        <Row>
                            <Col xs="6" className="player-card-image-container">
                                <img className='player-card-image' src={props.src}></img>
                            </Col>
                            <Col xs="6" className='text-center'>
                                <CardTitle className="player-card-title m-0">Team {props.name}</CardTitle>
                                <p>{props.goldIcon ? <i class="fa-solid fa-coins" style={{color: 'gold' }}></i>:''} {' ' + props.points}</p>
                            </Col>
                            {thisPointsChange && thisPointsChange.team === props.teamKey && (
                                <div className={`fading-number`}>
                                    {thisPointsChange.change > 0 ? `+${thisPointsChange.change}` : thisPointsChange.change}
                                </div>
                            )}
                        </Row>
                    </Container>
                <div className="player-link-group nunito text-center">
                    <p className="player-card-custom-link text-center player-card-add-button" onClick={props.onAddPointsClick}><i class="fa-solid fa-plus"></i></p>
                    <p className="player-card-custom-link text-center player-card-minus-button" onClick={props.onMinusPointsClick}><i class="fa-solid fa-minus"></i></p>
                </div>
                
            </Card>
    </div>
  );
};

// Define default props to handle cases where props might be undefined

export default PlayerCard;