import { useState, useContext,useCallback, useEffect, useRef} from "react";
import Phaser from 'phaser';
import BootState from './states/BootState';
import GameState from './states/GameState';
import PreloadState from './states/PreloadState';
import MainMenuState from './states/MainMenuState';
import QuizModal from '../../components/QuizModal'
import API from '../../utils/API'
import './style.css';
import FinalScoreWaiting from "../../components/FinalScoreWaiting";
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

const MildyInfuriatedBird = (props) => {
  const gameContainerRef = useRef(null);
    let game;
    const { handleScoreChange,isGameOver } = props; // Destructure handleScoreChange from props
    const [phaserGame, setPhaserGame] = useState(null); // State to store the Phaser game instance
    const [score, setScore] = useState(0); // React state for score
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const[questionsPool,setQuestionsPool] = useState([]);
    const [questionSet, setQuestionSet] = useState({});
    const[nextQuestion,setNextQuestion] = useState({});
    useEffect(()=>{
      if(isModalOpen){
        if(handleScoreChange){
          handleScoreChange(score);
        }    
      }
    },[isModalOpen])

    useEffect(() => {
      if (isGameOver) {
        handleScoreChange(score);
        handleGameEnd(); // Call the function when isGameOver becomes true
      }
    }, [isGameOver]);

    const handleGameEnd = () => {
      // Implement your game end logic here
      if (phaserGame) {
        // Destroy the Phaser game instance when the game is over
        phaserGame.destroy(true);
        setPhaserGame(null); // Clear the Phaser game instance from the state
      }
      
    };
  
    const handleAnswer = (answer) => {
      // Resume the game or proceed based on the answer
      if(answer === 'true'){
        setIsModalOpen(false)       

        if (phaserGame) {
          // Access the scene manager and restart the scene
          const gameState = phaserGame.scene.getScene('GameState');
          if (gameState) {
            gameState.scene.restart('GameState');

            phaserGame.questionOpened = false;

          }
        }      
      } 
        const randomIndex = Math.floor(Math.random() * questionsPool.length);
        // Set the selected question
        setNextQuestion(questionsPool[randomIndex]);
    };
    
  useEffect(()=>{
    if(props.questionSet){
      setQuestionSet(props.questionSet);
      const shuffledQuestions = shuffleArray(props.questionSet.Questions); // Use slice to avoid mutating the original array
      let cleanedQuestions = []
      for (let i = 0; i < shuffledQuestions.length; i++) {
        if(shuffledQuestions[i].question){
          cleanedQuestions.push(shuffledQuestions[i])
        } 
      }
      setQuestionsPool(cleanedQuestions)
      const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
      // Set the selected question
      setNextQuestion(cleanedQuestions[randomIndex]);

    }else{
      
        API.getQuestionSet(10).then(res=>{
          setQuestionSet(res.data);
          const shuffledQuestions = shuffleArray(res.data.Questions); // Use slice to avoid mutating the original array
          let cleanedQuestions = []
          for (let i = 0; i < shuffledQuestions.length; i++) {
            if(shuffledQuestions[i].question){
              cleanedQuestions.push(shuffledQuestions[i])
            } 
          }
          setQuestionsPool(cleanedQuestions)
          const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
          // Set the selected question
          setNextQuestion(cleanedQuestions[randomIndex]);
        }).catch(err=>{
          console.log(err)
      })
    }
  },[])

  useEffect(() => {        
    let gameConfig = {
      type: Phaser.CANVAS,
      width: 1240,
      height: 720,
      parent:gameContainerRef,
      scene: [BootState,PreloadState, MainMenuState,GameState], 
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 1000 }
        }
      }, 
    }
    game = new Phaser.Game(gameConfig);
    game.settings = {
      lockOrientation: true,
      displayOrientation: 'landscape',
      storagePrefix: 'mif11_'
    };
    
    game.reactSetScore = setScore;
    // game.reactScore = score;
    game.openQuestion = openQuestion; // Expose modal control to Phaser
    setPhaserGame(game)
    // Clean up on unmount
    return () => {
      if (phaserGame) {
        phaserGame.destroy(true);
        setPhaserGame(null);
      }
    };
  }, []);
  
  const openQuestion= ()=>{
    setIsModalOpen(true);
    game.questionOpened = true
  }

  return (
    <div style={{overflow:'hidden', background:'black'}} className="h-100 w-100" >
      {isGameOver? 
      <div className="vertical-center waiting-room" style={{minHeight:"100vh"}}>
      <FinalScoreWaiting></FinalScoreWaiting>
      </div>
      :
      <>
      <div ref={gameContainerRef} id="mildyinfuriatingbird" />
      <QuizModal 
        isOpen={isModalOpen} 
        onRequestClose={() => setIsModalOpen(false)} 
        onAnswer={handleAnswer} 
        question={nextQuestion}
      />
      </>   
      }
    </div>
  );

};

export default MildyInfuriatedBird;